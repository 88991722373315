<template>
  <div @keyup="handleKeyup" ref="modal" tabindex="0">

  <div class="fixed left-2 bottom-2">M <audio id="audio" ref="audioDevice" :src="`audio/${ui.taxValue}.mp3`"></audio></div>

  <div v-if="ui.preConfirm" class="fixed w-full h-12 text-center z-20 align-middle bottom-0 bg-green-100">You classified {{currentClassification.title}} as {{ui.autoSelection }}. If correct press enter</div>

  <div class="w-full flex">

    <div class="w-1/2 max-h-100vh border-r">

      <div class="flex flex-wrap justify-center p-8 max-h-full overflow-hidden">
        <div  v-if="(!ui.analyzeCollaborations)" class="flex flex-wrap justify-center p-8 max-h-full overflow-hidden">
        <div  class="flex w-1/2 flex-col" v-for="image in ui.rss" :key="image.id">
          <a :href="`https://www.youtube.com/watch?v=${image.id}`" target="_blank">
            <img :src="`https://i.ytimg.com/vi/${image.id}/hqdefault.jpg`" crossOrigin='anonymous' class="image-thumb rounded-lg m-2 bg-black" style="display: none" ref="thumbImages"/>
            <div :style="`background-image: url('https://i.ytimg.com/vi/${image.id}/hqdefault.jpg'); width: 240px; height: 135px`" class="rounded-lg bg-cover bg-center m-2 bg-black" />
          </a>
          <h3>{{ image.title }}</h3>
      </div>
    </div>

      <div v-if="(ui.analyzeCollaborations)">
        <div v-for="content in ui.rss" :key="content.id">
        <div class="">
          <a :href="`https://www.youtube.com/watch?v=${content.id}`" target="_blank"><div :style="`background-image: url('https://img.youtube.com/vi/${content.id}/maxresdefault.jpg'); width: 100px; height: 60px`" class="rounded-lg bg-cover m-2 bg-black" /></a>

          <span @click="extractName(content.title, content.id)" class="hover:bg-slate-200 cursor-pointer">{{content.title}}</span><br/>
          <span class="hover:bg-slate-200 text-sm cursor-pointer" v-for="str,index in splitSentences(content.description.replace(/(?:https?|ftp):\/\/[\n\S]+/g, ''))" :key="index" @click="extractName(str, content.id)">{{str}}</span>
        </div>
      </div>
      </div>
    </div>

    </div>
    <div class="w-1/2 p-12 max-h-full overflow-scroll">
      <div>
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Basic metadata</h2>
        <h4 class="ml-7 text-lg"><a :href="`https://www.youtube.com/channel/${currentClassification.selectChannelId}`" target="_blank">{{currentClassification.title}} | {{ metadata.channelId }} {{ metadata.notes }}</a></h4>
        <p class="ml-7" 
          @mouseover="currentClassification.translated ? ui.showTranslation = true : null" 
          @mouseleave="ui.showTranslation = false">
            {{ui.showTranslation ? currentClassification.descriptionTranslated : currentClassification.description}} 
          <span class="bg-black text-white rounded text-sm px-1.5 py-0.5 cursor-pointer" @click="translate()" v-if="currentClassification.description && !currentClassification.translated">Translate</span>
        </p><br/>
        <p class="ml-7 text-gray-700">{{currentClassification.country}} | {{currentClassification.subscriberCount}} subscribers | {{currentClassification.videoCount}} videos</p>

        <div v-if="metadata && metadata.gpt">
          <h2 class="text-sm uppercase mb-2 mt-4 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>GPT Classification</h2>

          <div class="px-6">
          <span v-if="metadata.gpt.classification !== 'false'" class="py-3 mt-2 rounded-full pr-4 text-white" style="font-family: IBM Plex Mono; font-size: .75rem; background-image: linear-gradient(25deg,#d64c7f,#ee4758 50%);">
            <span class="px-3 py-1.5 text-xs rounded-full font-semibold text-gray-800 bg-gray-100 border border-gray-800 dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 mx-2">A</span> 
            {{metadata.gpt.classification_name}} {{ metadata.gpt.classification }} 
          </span>

          — likeliness treshold {{ metadata.gpt.treshold }} {{ metadata.gpt.language ? metadata.gpt.language : "" }}<br/>
          <div class="mt-4">{{ metadata.gpt.explanation }}</div>
          </div>
        </div>

      </div>

      <div class="mt-8">
        <!-- <div class="mt-8 animate-pulse"> -->
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5 mb-2"></div>Classification</h2>
        <!-- <p class="ml-7">Currently checking</p> -->
        <div class="ml-7">

          <span v-if="getName(metadata.autoFirstRaditubeTaxonomy) !== 'Unknown category'" class="bg-green-200 py-3 rounded-full pr-4" style="font-family: IBM Plex Mono; font-size: .75rem">
            <span class="px-3 py-1.5 text-xs rounded-full font-semibold text-gray-800 bg-gray-100 border border-gray-200 dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 mx-2">C</span> 
            {{ getName(metadata.autoFirstRaditubeTaxonomy) }} {{ metadata.autoFirstRaditubeTaxonomy }}
          </span>

          <span v-if="getName(metadata.otherAutoTaxonomy[0]) !== 'Unknown category'" class="bg-orange-100 py-3 ml-4 rounded-full pr-4" style="font-family: IBM Plex Mono; font-size: .75rem">
            <span class="px-3 py-1.5 text-xs rounded-full font-semibold text-gray-800 bg-gray-100 border border-gray-200 dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 mx-2">H</span> 
            {{ getName(metadata.otherAutoTaxonomy[0]) }} {{ metadata.otherAutoTaxonomy[0] }}
          </span>

          <span v-if="getName(metadata.otherAutoTaxonomy[1]) !== 'Unknown category'" class="bg-pink-100 py-3 ml-4 rounded-full pr-4" style="font-family: IBM Plex Mono; font-size: .75rem">
            <span class="px-3 py-1.5 text-xs rounded-full font-semibold text-gray-800 bg-gray-100 border border-gray-200 dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 mx-2">L</span> 
            {{ getName(metadata.otherAutoTaxonomy[1]) }} {{ metadata.otherAutoTaxonomy[1] }}
          </span>
          
          
          <br/><br/>
          <span v-if="customHotKeyBool && getName(hotkeys.code) !== 'Unknown category'" class="bg-green-200 py-3 rounded-full pr-4" style="font-family: IBM Plex Mono; font-size: .75rem; background-image: linear-gradient(to right, #ed4264, #ffedbc);">
            <span class="px-3 py-1.5 text-xs rounded-full font-semibold text-gray-800 bg-gray-100 border border-gray-200 dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 mx-2">M</span> 
            {{ getName(hotkeys.code) }} {{ hotkeys.name }}
          </span><br/><br/>

          If none are fitting, pick custom classification or skip.<br/>

          <div class="mt-4">
            <button 
              type="button" 
              @click="currentClassification.humanHarmful = 'classify'" 
              v-bind:class="{ 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' : currentClassification.autoHarmful === 'harmful' || currentClassification.humanHarmful === 'harmful', 
                              'py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700' : currentClassification.humanHarmful !== 'harmful' }"
            >Classify</button>

            <button 
              type="button" 
              class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="this.$emit('next-channel', 'skip', 'skip')" 
            >Skip</button>
          </div>
        </div>
      </div>

      <div class="mt-8" v-if="currentClassification.humanHarmful === 'classify'">
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Raditube Taxonomy Check</h2>
        <div class="ml-7">
          <span class="mb-2">Taxonomy System</span><br/>
          <span class="mb-2">{{ metadata.autoFirstRaditubeTaxonomy }}</span>
          <v-select :options="taxonomy.raditube" label="title" v-model='ui.selectedTaxonomy'></v-select><br/>
          <v-select v-if="secondTaxonomy" :options="taxonomy.raditube" label="title" v-model='ui.selectedTaxonomyTwo'></v-select><br/>

          <span @click="addAnotherTaxonomy()">Add another classification</span>
          
        </div>

        <h2 class="text-sm uppercase mb-2 mt-8 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>IAB/GDI check</h2>
        <div class="ml-7">
          <input checked id="checked-checkbox-gdi" type="checkbox" value="" v-model="ui.clients.gdi" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="checked-checkbox-gdi" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">GDI (Disinformation)</label><br/>
          <input checked id="checked-checkbox-af" type="checkbox" value="" v-model="ui.clients.af" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="checked-checkbox-af" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">AF</label><br/>

        <div>
          <h2 id="accounts" class="text-sm uppercase mb-2 mt-8 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Other Accounts</h2>
          <div class="ml-7">
            <span>We found the following accounts and monetization strategies for {{currentClassification.title}}. Click on the ones you would like to remove:</span>
            <ul v-if="(ui.platforms.length >= 1)" class="flex flex-col items-start">
              <li 
                v-for="(platform, index) in ui.platforms" 
                :key="platform[0]"
                @click="ui.platforms[index][2] = !ui.platforms[index][2]"
                :class="{'rounded bg-green-100 text-sm px-2 mt-2 inline-block cursor-pointer hover:bg-red-50': ui.platforms[index][2], 'line-through bg-red-100 rounded text-sm px-2 mt-2 inline-block cursor-pointer hover:bg-green-50': !ui.platforms[index][2]}"
                >{{platform[0]}} | {{platform[1]}}
                <svg v-if="ui.platforms[index][2]" class="h-3 w-3 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-8">
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Verify to go to the next channel</h2>
        <textarea v-model="currentClassification.notes" class="ml-7 border rounded w-full" placeholder="You can provide additional notes here."></textarea>
        <div class="ml-7">
          <button @click="next()" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-green-700 rounded-lg border border-gray-200 hover:bg-green-600 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit and go to next channel</button>
        </div>
      </div>
      </div>

      <div class="mt-8" v-if="currentClassification.humanHarmful === 'non-harmful'">
      <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>First IAB Category ({{ui.firstIABTokens}} — ${{(0.00002*ui.firstIABTokens)}})</h2>
        <div class="ml-7">
          <span class="mb-2" v-bind:class="{ 'animate-pulse' : ui.loadingFirstIAB }" v-if="ui.loadingFirstIAB">
            <svg class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            Auto-Determining First Category</span>
          <span class="mb-2" v-if="!ui.loadingFirstIAB">Channel has been auto-classified as {{currentClassification.autoFirstIABCategory}}. Does this classification make sense?</span>
          <v-select :options="taxonomy.iabMainCategories" label="name" v-model='ui.selectedFirstIAB'></v-select>

          <div class="mt-4" v-if="!ui.loadingFirstIAB || ui.selectedFirstIAB">
          <button type="button" @click="(ui.proceedFirstIAB = true, currentClassification.humanFirstIABCategory = ui.selectedFirstIAB, prepareSecondTaxonomy())" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Proceed</button>
          <button type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">I don't know</button>
          </div>
        </div>

        <div>
          <h2 class="text-sm uppercase mb-2 mt-8 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Other Accounts</h2>
          <div class="ml-7">
            <span>We found the following accounts and monetization strategies for {{currentClassification.title}}. Click on the ones you would like to remove:</span>
            <ul v-if="(ui.platforms.length >= 1)" class="flex flex-col items-start">
              <li 
                v-for="(platform, index) in ui.platforms" 
                :key="platform[0]"
                @click="ui.platforms[index][2] = !ui.platforms[index][2]"
                :class="{'line-through': !ui.platforms[index][2]}"
                class="rounded bg-slate-100 text-sm px-2 mt-1 inline-block cursor-pointer"
                >{{platform[0]}} | {{platform[1]}}
                <svg v-if="ui.platforms[index][2]" class="h-3 w-3 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div class="mt-8" v-if="(currentClassification.humanHarmful === 'non-harmful' && ui.proceedFirstIAB && ui.showSecondCategory)">
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>Second IAB Category</h2>
        <div class="ml-7">
          <span v-bind:class="{ 'animate-pulse' : ui.loadingSecondIAB }">If possible, also select a second IAB category </span><br/>
          <v-select :options="taxonomy.iabFullTaxonomy.filtered" label="name" v-model='ui.selectedSecondIAB' @input="setSafety"></v-select>
        </div>
      </div> -->

      <!-- <div class="mt-8" v-if="(currentClassification.humanHarmful === 'non-harmful' && ui.proceedSecondIAB || currentClassification.humanHarmful === 'non-harmful' && ui.proceedFirstIAB && ui.showSecondCategory) || currentClassification.humanHarmful === 'insufficient-data' ">
        <h2 class="text-sm uppercase mb-2 flex"><div class="w-4 h-4 rounded-full border border-black mr-3 mt-0.5"></div>If IAB category doesn't fit, please mention that here</h2>
        <textarea v-model="currentClassification.notes" class="ml-7 border rounded w-full" placeholder="You can provide additional notes here."></textarea>
        <div class="ml-7">
          <button @click="next()" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-green-700 rounded-lg border border-gray-200 hover:bg-green-600 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit and go to next</button>
        </div>
      </div> -->
    </div>
  </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
import openSocialTaxonomy from '../data/new_taxonomy.json';

import iabRaditubeTaxonomy from '../data/iab_raditube_3_0.json';
import { findIabCode } from '../scripts/find_iab_code.js';
import garm from '../data/garm.json';
import parseString from 'xml2js';
import getUrls from 'get-urls';
import { normalize } from '../scripts/normalize.js';

export default {
  name: 'ClassificationInterface',
  props: {
    msg: String,
    currentId: String,
    metadata: Object,
    user: String,
    sub: String,
    customHotKeyBool: Boolean,
    hotkeys: Object
  },
  data() {
    return {
      secondTaxonomy: false,
      garm: {
        floor: "",
        high: "",
        medium: "",
        low: ""
      },
      taxonomy: {
        otherAutoTaxonomy: ["", "", ""],
        raditube: openSocialTaxonomy,
        iabMainCategories: iabRaditubeTaxonomy,
        iabFullTaxonomy: {
          full: iabRaditubeTaxonomy,
          filtered: [] // refactor
        }
      },
      ui: {
        taxValue: "",
        autoSelection: "",
        rss: {},
        preConfirm: false,
        showTranslation: false,
        selectedFirstIAB: null,
        firstIABTokens: null,
        loadingFirstIAB: true,
        proceedFirstIAB: false,
        loadingSecondIAB: true,
        selectedSecondIAB: null,
        proceedSecondIAB: false,
        showSecondCategory: true,
        selectedTaxonomy: null,
        selectedTaxonomyTwo: null,
        platforms: [],
        selectedIAB: null,
        clients: {
          gdi: false,
          af: false
        }
      },
      currentClassification: {
        selectChannelId: this.currentId,
        title: "",
        description: "",
        country: "",
        translated: false,
        descriptionTranslated: "",
        subscriberCount: 0,
        videoCount: 0,
        gdi: true,
        classificationBy: "uuid",
        classificationDate: new Date(),
        autoHarmful: this.metadata.harmfulnessAuto,
        humanHarmful: "",
        autoFirstIABCategory: "",
        humanFirstIABCategory: "",
        notes: ""
      }
    }
  },
  watch: {
    currentId(newValue) {
      this.getChannelData(newValue)
      this.getRss(newValue)
    },
    'ui.selectedTaxonomy': function () {
      console.log(this.ui.selectedTaxonomy)
      this.setSafety();
    },
    'ui.selectedIAB': function (newValue) {
      this.retrieveGarm(newValue)
    },
    'ui.analyzeCollaborations': function () {

    },
  },
  beforeMount() {
    this.getChannelData(this.currentId)
  },
  async mounted() {
    await this.getRss(this.currentId)
    this.$refs.modal.focus();

    this.taxonomy.otherAutoTaxonomy = JSON.parse(this.metadata.otherAutoTaxonomy);
  },
  methods: {
    setKeysHandling(taxValue) {
      console.log(taxValue);
      console.log('tax value')
      this.ui.taxValue = taxValue;
      this.$nextTick(() => {
        this.$refs.audioDevice.play();
      })
          this.currentClassification.humanHarmful = 'classify';

          // set this to first taxonomy
          this.setOSTaxonomy(taxValue);


          // show bar to mention user can press enter button to confirm
          this.ui.autoSelection = this.getName(taxValue);
          this.ui.preConfirm = true;
        
    },
    handleKeyup(event) {
      if (event.keyCode === 39) { this.$emit('next-channel', 'skip', 'skip'); }
      if (event.keyCode === 49) { this.currentClassification.humanHarmful = 'classify' }
      if (event.keyCode === 65 && this.currentClassification.humanHarmful === '') { 
        this.currentClassification.humanHarmful = 'classify';
        this.setKeysHandling(this.metadata.gpt.classification)
      }
      if (event.keyCode === 67 && this.currentClassification.humanHarmful === '') { 
        this.currentClassification.humanHarmful = 'classify';
        console.log(this.metadata);
        this.setKeysHandling(this.metadata.autoFirstRaditubeTaxonomy)
      }
      if (event.keyCode === 77 && this.currentClassification.humanHarmful === '') { 
        this.currentClassification.humanHarmful = 'classify';
        this.setKeysHandling(this.hotkeys.code)
      }
      if (event.keyCode === 72 && this.currentClassification.humanHarmful === '') { 
        this.setKeysHandling(this.metadata.otherAutoTaxonomy[0])
      }
      if (event.keyCode === 76 && this.currentClassification.humanHarmful === '') { 
        this.setKeysHandling(this.metadata.otherAutoTaxonomy[1])
      }
      if (event.keyCode === 80) { this.ui.proceedFirstIAB = true  }
      if (event.keyCode === 13 || event.keyCode === 49) { 
        if (this.ui.preConfirm) {
          this.next();
        }
      }
    },
    setIABTaxonomy(code) {
      const parsedCode = Number(code);
      const cleanCode = isNaN(parsedCode) ? code : parsedCode;
      this.ui.selectedFirstIAB = iabRaditubeTaxonomy.find((item) => item.unique_id == cleanCode);
      this.currentClassification.humanFirstIABCategory = this.ui.selectedFirstIAB;
      this.prepareSecondTaxonomy()
    },
    setOSTaxonomy(code) {
      this.ui.selectedTaxonomy = this.taxonomy.raditube.find((item) => item.code == code);
    },
    getChannelData(channelId) {
      const API_KEY = "AIzaSyCcx4QW9d22O8wx87JlP3a289ohyLd30gc";
      const apiURL = `https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet&id=${channelId}&key=${API_KEY}`;

      fetch(apiURL)
        .then(response => response.json())
        .then(data => {
          const res = data.items[0]
          this.currentClassification.title = res.snippet.title;
          this.currentClassification.description = res.snippet.description;
          this.currentClassification.country = res.snippet.country;
          this.currentClassification.subscriberCount = res.statistics.subscriberCount;
          this.currentClassification.videoCount = res.statistics.videoCount;
        })
        .catch(error => {
          console.error(error);
        });
    },
    countElements(arr) {
      var countsArray = [];
      var counts = {};

      for (var i = 0; i < arr.length; i++) {
        var element = arr[i];

        if (counts[element]) { counts[element]++;
        } else { counts[element] = 1 }
      }

      for (var key in counts) {
        countsArray.push([key, counts[key], true]);
      }

      return countsArray;
    },
    getName(id) {
      for (let n = 0; n < iabRaditubeTaxonomy.length; n++) {
        if (iabRaditubeTaxonomy[n].unique_id == id) {
          return iabRaditubeTaxonomy[n].name
        }
      }
          // find the category object with the matching "Code" property
        const category = openSocialTaxonomy.find(cat => cat.code === id);
        if (category) { // if a matching category was found, return its "Sub_Category" value
          return category.title;
        } else { // if no matching category was found
          return "Unknown category";
        }
    },
    async getRss(id) {
        const response = await fetch(`https://proxy-rss.onrender.com/https://www.youtube.com/feeds/videos.xml?channel_id=${id}`);
        const data = await response.text();
        const xml = data;
        this.ui.rss = [];
        let _this = this;

        parseString.parseString(xml, (err, result) => {
          const entries = result.feed.entry;
          if (entries) {
            _this.ui.rss.push(
              ...entries.map(({ 'yt:videoId': [id], title: [title], 'media:group': [{ 'media:description': [description] }] }) => ({
                id,
                title,
                description,
              }))
            );
          } else {
            this.next();
          }
        });

        
        let urlArr = [];

        for (let i = 0; i < this.ui.rss.length; i++) {
          const btc = this.ui.rss[i].description.match(/[13][a-km-zA-HJ-NP-Z1-9]{25,34}/g);
          const eth = this.ui.rss[i].description.match(/0x[a-fA-F0-9]{40}/g);
          if (btc) { urlArr.push([`btc:${btc[0]}`])}
          if (eth) { urlArr.push([`eth:${eth[0]}`])}

          const urls = getUrls(this.ui.rss[i].description);

          urls.forEach((url) => {
            if (normalize(url) !== false) {
              urlArr.push(normalize(url))
            }
          })
        }

        this.ui.platforms = this.countElements(urlArr);
        this.ui.platforms.forEach((platform, index) => {
          if (platform[1] === 1) {
            this.ui.platforms[index][2] = false;
          }
        })
    },
    async translate() {
      const token = await this.$auth0.getAccessTokenSilently();

      try {
        const { data } = await axios.post("https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/translate",
          {
            text: this.currentClassification.description,
            target_lang: 'EN'
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        this.currentClassification.translated = true;
        this.currentClassification.descriptionTranslated = data.translations[0].text;

      } catch (error) {
        console.log(error);
      }
    },
    addAnotherTaxonomy() {
      this.secondTaxonomy = true;
    },
    raditubeTaxonomyFilter(data, keyType) {
      let all = [];
      data.forEach((d) => {
        if (d.type === keyType || (Array.isArray(d.type) && d.type.some((t) => t === keyType))) {
          all.push(d);
        }
      });
      return all;
    },
    iabFilter(data) {
      let all = [];
      data.forEach((d) => {
        if (d.parent === "v9i3On" || (Array.isArray(d.parent) && d.parent.some((t) => t === "v9i3On"))) {
          all.push(d);
        }
      });
      return all;
    },
    retrieveGarm(val) {
      let floor = [];
      let high = [];
      let medium = [];
      let low = [];
      garm.forEach((g) => {
        if (g.category === val.unique_id) {
          floor.push(g.floor)
          high.push(g.high)
          medium.push(g.medium)
          low.push(g.low)
        }
      })

      this.garm = {
        floor: floor.join('\n'),
        high: high.join('\n'),
        medium: medium.join('\n'),
        low: low.join('\n')
      }

      console.log(this.garm);
    },
    // async getFirstIABCategory() {
    //   let categories = [];
    //   iabRaditubeTaxonomy.forEach((category) => {
    //     if (category.type === "main") {
    //       categories.push(category.name)
    //     }
    //   })

    //   try {
    //     const response = await this.getIABCategory(categories.join('\n'));
    //     this.currentClassification.autoFirstIABCategory = JSON.parse(response.choices[0].text).category;
    //     this.ui.firstIABTokens = response.usage.total_tokens;
    //     this.ui.loadingFirstIAB = false;
    //     this.setFirstTaxonomy(JSON.parse(response.choices[0].text).category)
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async getIABCategory(categories) {
      const token = await this.$auth0.getAccessTokenSilently();

      try {
        const { data } = await axios.post("https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/llmABC",
          {
            description: this.currentClassification.translated ? 
              this.currentClassification.descriptionTranslated : 
              this.currentClassification.description,
            categories: categories
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        return data
      } catch (error) {
        console.log(error);
      }
    },
    provideStatus() {
      if(this.currentClassification.humanHarmful === "classify" || this.currentClassification.humanHarmful === "harmful" ) {
        return "reviewed"
      }
    },
    extractName(str, id) {
      console.log(str);
      console.log(id);
    },
    splitSentences(str) {
      return str.match(/[^.!?]+[.!?]+/g) // eslint-disable-line no-use-before-define
    },
    prepareSecondTaxonomy() {
      let n = 0;
      for (let i = 0; i < this.taxonomy.iabMainCategories.length; i++) {
        if (this.taxonomy.iabMainCategories[i].parent === this.currentClassification.humanFirstIABCategory.unique_id) {
          this.taxonomy.iabFullTaxonomy.filtered.push(this.taxonomy.iabMainCategories[i])
          n++
        }
      }

      if (n === 0) {
        this.ui.showSecondCategory = false; 
        this.ui.proceedSecondIAB = true;
      }
    },
    setSafety() {
      this.currentClassification.garm = this.ui.selectedTaxonomy.GARM_Framework;
      this.ui.clients.gdi = this.ui.selectedTaxonomy.gdi;
    },
    async next() {
      let codes = [];

      function convertCode(value) {
        if (typeof value !== 'string') {
          return value;
        }

        const numberValue = parseFloat(value);

        if (!isNaN(numberValue) && value.includes('.') && !Number.isInteger(numberValue)) {
          return value;
        }

        if (!isNaN(numberValue) && !value.includes('.')) {
          return parseInt(value, 10);
        }

        return value;
      }

      if (this.ui.selectedTaxonomy) {
        const code = convertCode(this.ui.selectedTaxonomy.code);
        codes.push(code);
      }

      if (this.ui.selectedTaxonomyTwo) {
        const code = convertCode(this.ui.selectedTaxonomyTwo.code);
        codes.push(code);
      }

      console.log(this.ui.rss);

      
      let channelArr = [{
        id: this.currentId,
        subscriberCount: this.currentClassification.subscriberCount ? parseInt(this.currentClassification.subscriberCount) : 0,
        clientsGdi: this.ui.clients.gdi ? 1 : 0,
        checkedByName: this.user,
        checkedById: this.sub,
        taxonomy: codes ? codes : "",
        notesByReviewer: this.currentClassification.notes,
        videoCount: this.currentClassification.videoCount ? parseInt(this.currentClassification.videoCount) : 0,
        title: this.currentClassification.title,
        gptExplanation: this.metadata.gpt && this.metadata.gpt.explanation ? this.metadata.gpt.explanation : "",
        videos: this.ui.rss.map(item => item.id),
        language: this.metadata.gpt && this.metadata.gpt.language ? this.metadata.gpt.language : "",
        description: this.currentClassification.description,
        country: this.currentClassification.country
      }]
      
      
      
      const token = await this.$auth0.getAccessTokenSilently();
      
      try {
        // const post = await axios.post('https://flllafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/post-classifications', channelArr, {
          const post = await axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/post-classification/id/52', channelArr, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        return post;
      } catch (err) {
        console.log(err);
      }

      this.$emit('next-channel', 'add', codes.length !== 0 ? codes : findIabCode(this.currentClassification.humanFirstIABCategory.name));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.v-popper__popper {
  width: 500px !important;
}

.mono-style {
  font-family: 'IBM Plex Mono';
  font-size: .75rem;
}

</style>
