const iabCodes = require('../data/iab_raditube_3_0.json');

const findIabCode = (iabName) => {
    for (const iabCode of iabCodes) {
        if (iabCode.name === iabName) {
            return iabCode.unique_id;
        }
    }
    return null;
}

export { findIabCode }