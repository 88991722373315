<template>
  <div class="flex justify-center" ref="graph">
    Bla
  </div>
</template>

<script>
import * as LightweightCharts from 'lightweight-charts';

export default {
  mounted() {

    var chart = LightweightCharts.createChart(this.$refs.graph, {
      width: 600,
      height: 300,
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
    });

    // document.body.appendChild(chartElement);

    var areaSeries = chart.addAreaSeries({
      topColor: 'rgba(33, 150, 243, 0.56)',
      bottomColor: 'rgba(33, 150, 243, 0.04)',
      lineColor: 'rgba(33, 150, 243, 1)',
      lineWidth: 2,
    });

   

    const lightTheme = {
      chart: {
        layout: {
          background: {
            type: 'solid',
            color: '#FFFFFF',
          },
          lineColor: '#2B2B43',
          textColor: '#191919',
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            color: '#f0f3fa',
          },
        },
      },
      series: {
        topColor: 'rgba(33, 150, 243, 0.56)',
        bottomColor: 'rgba(33, 150, 243, 0.04)',
        lineColor: 'rgba(33, 150, 243, 1)',
      },
    };

    var themesData = {
      Light: lightTheme,
    };

    function syncToTheme(theme) {
      chart.applyOptions(themesData[theme].chart);
      areaSeries.applyOptions(themesData[theme].series);
    }

const data = [
  {
    "date_added": "2023-05-24",
    "count": "6110"
  },
  {
    "date_added": "2023-05-23",
    "count": "3845"
  },
  {
    "date_added": "2023-05-22",
    "count": "414"
  },
  {
    "date_added": "2023-05-21",
    "count": "1241"
  },
  {
    "date_added": "2023-05-20",
    "count": "1232"
  },
  {
    "date_added": "2023-05-19",
    "count": "597"
  },
  {
    "date_added": "2023-05-18",
    "count": "525"
  },
  {
    "date_added": "2023-05-17",
    "count": "2585"
  },
  {
    "date_added": "2023-05-16",
    "count": "1395"
  },
  {
    "date_added": "2023-05-15",
    "count": "909"
  },
  {
    "date_added": "2023-05-14",
    "count": "255"
  },
  {
    "date_added": "2023-05-13",
    "count": "522"
  },
  {
    "date_added": "2023-05-12",
    "count": "2879"
  },
  {
    "date_added": "2023-05-11",
    "count": "3218"
  },
  {
    "date_added": "2023-05-10",
    "count": "1936"
  },
  {
    "date_added": "2023-05-09",
    "count": "1254"
  },
  {
    "date_added": "2023-05-08",
    "count": "645"
  },
  {
    "date_added": "2023-05-07",
    "count": "1099"
  },
  {
    "date_added": "2023-05-06",
    "count": "1408"
  },
  {
    "date_added": "2023-05-05",
    "count": "1389"
  },
  {
    "date_added": "2023-05-04",
    "count": "974"
  },
  {
    "date_added": "2023-05-03",
    "count": "1243"
  },
  {
    "date_added": "2023-05-02",
    "count": "487"
  },
  {
    "date_added": "2023-05-01",
    "count": "40"
  },
  {
    "date_added": "2023-04-30",
    "count": "1085"
  },
  {
    "date_added": "2023-04-29",
    "count": "1561"
  },
  {
    "date_added": "2023-04-28",
    "count": "2557"
  },
  {
    "date_added": "2023-04-27",
    "count": "1151"
  },
  {
    "date_added": "2023-04-26",
    "count": "19"
  },
  {
    "date_added": "2023-04-25",
    "count": "1612"
  },
  {
    "date_added": "2023-04-24",
    "count": "639"
  },
  {
    "date_added": "2023-04-23",
    "count": "43"
  },
  {
    "date_added": "2023-04-22",
    "count": "92"
  },
  {
    "date_added": "2023-04-21",
    "count": "1082"
  },
  {
    "date_added": "2023-04-20",
    "count": "901"
  },
  {
    "date_added": "2023-04-18",
    "count": "204"
  },
  {
    "date_added": "2023-04-17",
    "count": "144"
  },
  {
    "date_added": "2023-04-13",
    "count": "651"
  },
  {
    "date_added": "2023-04-11",
    "count": "528"
  },
  {
    "date_added": "2023-04-10",
    "count": "3047"
  },
  {
    "date_added": "2023-04-09",
    "count": "36"
  },
  {
    "date_added": "2023-04-07",
    "count": "475"
  },
  {
    "date_added": "2023-04-06",
    "count": "765"
  },
  {
    "date_added": "2023-04-05",
    "count": "90"
  },
  {
    "date_added": "2023-04-04",
    "count": "252"
  },
  {
    "date_added": "2023-04-03",
    "count": "248"
  },
  {
    "date_added": "2023-04-02",
    "count": "777"
  },
  {
    "date_added": "2023-04-01",
    "count": "490"
  },
  {
    "date_added": "2023-03-30",
    "count": "209"
  },
  {
    "date_added": "2023-03-29",
    "count": "363"
  },
  {
    "date_added": "2023-03-28",
    "count": "356"
  },
  {
    "date_added": "2023-03-27",
    "count": "360"
  },
  {
    "date_added": "2023-03-25",
    "count": "153"
  },
  {
    "date_added": "2023-03-22",
    "count": "29"
  },
  {
    "date_added": "2023-03-15",
    "count": "23"
  },
  {
    "date_added": "2023-03-14",
    "count": "811"
  },
  {
    "date_added": "2023-03-13",
    "count": "13"
  },
  {
    "date_added": "2023-03-12",
    "count": "1477"
  },
  {
    "date_added": "2023-03-11",
    "count": "818"
  },
  {
    "date_added": "2023-03-10",
    "count": "939"
  },
  {
    "date_added": "2023-03-09",
    "count": "975"
  },
  {
    "date_added": "2023-03-08",
    "count": "561"
  },
  {
    "date_added": "2023-03-07",
    "count": "188"
  },
  {
    "date_added": "2023-03-06",
    "count": "205"
  },
  {
    "date_added": "2023-03-05",
    "count": "512"
  },
  {
    "date_added": "2023-03-04",
    "count": "1500"
  },
  {
    "date_added": "2023-03-03",
    "count": "1297"
  },
  {
    "date_added": "2023-03-02",
    "count": "451"
  },
  {
    "date_added": "2023-03-01",
    "count": "454"
  },
  {
    "date_added": "2023-02-28",
    "count": "688"
  },
  {
    "date_added": "2023-02-27",
    "count": "520"
  },
  {
    "date_added": "2023-02-26",
    "count": "248"
  },
  {
    "date_added": "2023-02-23",
    "count": "2"
  },
  {
    "date_added": "2023-02-22",
    "count": "1"
  },
  {
    "date_added": "2023-02-21",
    "count": "2"
  },
  {
    "date_added": "2023-02-20",
    "count": "9"
  },
  {
    "date_added": "2023-02-19",
    "count": "426"
  },
  {
    "date_added": "2023-02-18",
    "count": "395"
  },
  {
    "date_added": "2023-02-17",
    "count": "243"
  },
  {
    "date_added": "2023-02-16",
    "count": "334"
  },
  {
    "date_added": "2023-02-15",
    "count": "114"
  },
  {
    "date_added": "2023-02-14",
    "count": "10"
  },
  {
    "date_added": "2023-02-13",
    "count": "281"
  },
  {
    "date_added": "2023-02-12",
    "count": "558"
  },
  {
    "date_added": "2023-02-10",
    "count": "2"
  },
  {
    "date_added": "2023-02-09",
    "count": "157"
  },
  {
    "date_added": "2023-02-08",
    "count": "23"
  },
  {
    "date_added": "2023-02-06",
    "count": "96"
  }
]

let updatedData = data.map(item => ({
  time: item.date_added,
  value: parseInt(item.count)
}));

console.log(updatedData)

updatedData.sort((a, b) => new Date(a.time) - new Date(b.time));


areaSeries.setData(updatedData)

    // areaSeries.setData([
    //   { time: '2023-05-17', value: 39 },
    //   { time: '2023-05-18', value: 39 },
    //   { time: '2023-05-19', value: 39 },
    //   { time: '2023-05-20', value: 39 },
    //   { time: '2023-05-21', value: 39 },
    //   { time: '2023-05-22', value: 39 },
    //   { time: '2023-05-23', value: 3845},
    //   { time: '2023-05-24', value: 5593}
    // ]);
    syncToTheme('Light');
  }

}
</script>

<style>
.switcher {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 8px;
  color: #2196F3;
}

.switcher-item {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  color: #262b3e;
  background-color: transparent;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.switcher-item:hover {
  background-color: #f2f3f5;
}

.switcher-active-item {
  text-decoration: none;
  cursor: default;
  color: #262b3e;
}

.switcher-active-item,
.switcher-active-item:hover {
  background-color: #e1eff9;
}
</style>