<template>
  <div class="">
    <WorkSessionModal v-if="gettingWorkSessionToken" />
    <VideoModal v-if="showVideoModal" @hide-details="onHideDetails" :videoId="videoModal.videoId"
      :channelName="videoModal.channelName" :token="token" :lang="videoModal.lang" />

      <TagsWindow v-if="false" />


    <AddLabel v-if="showAddLabelModal" :code="currentCode" :taxonomySystem="taxonomy"
      :taxonomy="currentCatogory ? currentCatogory : ''" :token="token" @hide-details="onHideDetails" />

    <ProgressView v-if="showProgress" @hide-details="onHideDetails" :time="time" :classificationsN="classificationsN"
      :workSessionId="workSessionId" :token="token" />

    <IdleModal v-if="inactive" />
    <Popover class="relative isolate z-50">
      <div class="bg-white py-5">
        <div class="flex justify-between leading-6 px-6">

          <div>
            <span class="text-base font-semibold text-gray-900" style="line-height: 2.1rem">Classifications</span>

            <span @click="showProgress = true"
              class="ml-2 cursor-pointer inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
              <svg class="h-1.5 w-1.5 fill-green-500 animate-pulse" viewBox="0 0 6 6" aria-hidden="true">
                <circle cx="3" cy="3" r="3" />
              </svg>
              {{ parseInt(time / 60) }} minute(s) | {{ classificationsN }} classifications
            </span>

            <PopoverButton @click="openPanel = true"
              class="inline-flex ml-6 items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              To Be Analyzed
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
            </PopoverButton>
          </div>

          <div class="flex flex-wrap items-center gap-6 pl-4 sm:flex-nowrap sm:pl-6 lg:pl-8">

            <Popover class="relative">
              <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-7 text-gray-900">
                <span class="text-sm">Language</span>
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                  <div
                    class="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    <a v-for="item in languages" :key="item.name" :href="item.href"
                      class="block p-2 hover:text-indigo-600">{{ item.name }}</a>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>


            <h2 class="text-sm font-semibold leading-7 text-gray-900">Deadline ({{ deadline }})</h2>
            <div
              class="order-last flex w-full gap-x-8 text-sm font-semibold leading-7 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <span class="text-sm text-indigo-600" @click="deadline = generateFutureDate(7), getCategoriesDB()">This
                week</span>
              <span class="text-sm text-gray-700" @click="deadline = generateFutureDate(31), getCategoriesDB()">This
                month</span>
              <span class="text-sm  text-gray-700" @click="deadline = generateFutureDate(365), getCategoriesDB()">This
                year</span>
            </div>
          </div>
        </div>
      </div>

      <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
        <PopoverPanel v-if="openPanel"
          class="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5">
          <div
            class="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
            <div v-for="item in    options   " :key="item.name"
              class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6">
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
              </div>
              <div>
                <div @click="newTab(item.type); openPanel = false" class="cursor-pointer font-semibold text-gray-900">
                  {{ item.name }} <span
                    class="ml-2 inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">{{
                      categories[item.type].count }}</span>
                  <span class="absolute inset-0" />
                </div>
                <p class="mt-1 text-gray-600">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div class="bg-gray-50">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div
                class="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
                <div v-for="   item    in    callsToAction   " :key="item.name"
                  class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0"
                  @click=" handleClick(item.action)">
                  <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>


    <Confirmation v-if="showConfirmation" @submit-database="submitToDatabase" :loading="loading"
      @close-confirmation=" showConfirmation = false" :amount="currentChannels.length" />

    <NewDetails v-if="showDetails" id="UCbmDmawYt5bSV9UT6OF4fAQ" :channelMetadata="currentMetadata" :taxonomy="taxonomy" :token="token" :user="user"
      :sub="sub" @hide-details="onHideDetails" @submit-reclassify="sendToStorage"></NewDetails>

    <!-- <Details v-if="showDetails" @hide-details="onHideDetails" @submit-reclassify="sendToStorage"
      :metadata="currentMetadata" :taxonomy="taxonomy" :currentTaxonomy="currentCatogory" :token="token" :user="user"
      :sub="sub"></Details> -->

    <notification v-if="showNotification" type="delete" :heading="heading" :subline="subline"></notification>

    <div class="mt-3 relative border-b border-gray-200 px-6 pb-5 sm:pb-0">

      <div class="mt-4">
        <div class="flex flex-row">
          <div class="font-sm text-sm break-keep" style="min-width: 9rem;">MAIN CATEGORIES</div>
          <div class="overflow-scroll">
            <nav class="-mb-px flex space-x-8">
              <div @click=" tabs = tab.sub_categories" v-for="   tab    in    tabsCategories   " :key="tab.name"
                :class="[tab.gpt_classification === currentCatogory ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap cursor-pointer border-b-2 px-1 pb-4 text-sm font-medium']"
                :aria-current="tab.current ? 'page' : undefined">{{ tab.name }} ({{ tab.count }})</div>
            </nav>
          </div>
        </div>
      </div>


    </div>

    <div class="mt-3 relative border-b border-gray-200 px-6 pb-5 sm:pb-0">

      <div class="mt-4">
        <div class="flex flex-row">
          <div class="font-sm text-sm break-keep" style="min-width: 9rem;">SUB CATEGORIES</div>
          <div class="overflow-scroll">
          <nav class="-mb-px flex space-x-8">
            <div @click=" triggerCategory(tab.id)" v-for="   tab    in    tabs   " :key="tab.name"
              :class="[tab.gpt_classification === currentCatogory ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap cursor-pointer border-b-2 px-1 pb-4 text-sm font-medium']"
              :aria-current="tab.current ? 'page' : undefined">{{ tab.name }} ({{ tab.amount }})</div>
          </nav>
        </div>
            
        </div>
      </div>
    </div>



    <div class="text-center pt-10 pb-10" v-if="currentCatogory === ''">
      <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
      </svg>
      <h3 class="mt-2 text-sm font-semibold text-gray-900">Select a category</h3>
      <p class="mt-1 text-sm text-gray-500">Select both a category (to be analyzed) and a deadline.</p>
      <div class="mt-6">
        <button type="button"
          class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <PlayIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Watch Tutorial
        </button>
        <GraphView />
      </div>
    </div>


    <div class="mt-8 flow-root p-6" v-if="currentCatogory !== ''">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">

        <div class="inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
          <div
            class="flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p class="text-sm leading-6 text-white">
              <strong class="font-semibold">{{ findName(currentCatogory) }}</strong><svg viewBox="0 0 2 2"
                class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx="1" cy="1" r="1" />
              </svg>{{ taxonomy.find(item => item.code === currentCatogory)?.instructions || 'No instructions available'
              }}
            </p>
            <button type="button" class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
              <span class="sr-only">Dismiss</span>
              <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>

          <ul class="flex flex-wrap mt-2">
            <button v-for="keyword in this.allKeywords" :key="keyword" type="button" @click="changeKeyword"
              class="rounded cursor-pointer hover:bg-indigo-100 flex-shrink-0 bg-indigo-50 px-2 py-1 mt-2 mr-2 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
              {{ keyword }}
            </button>
          </ul>

        </div>

        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="relative">

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Channel
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Thumbs</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white" v-if="showChannels">
                <tr v-for="   (channel, index)   in    currentChannels   " :key="channel.channel_id"
                  :class="{ 'bg-red-50': channel.gpt_treshold <= 0.6, 'bg-slate-200': index === cursorMovement.currentIndex }"
                  ref="channelRefs">
                  <td
                    :class="['whitespace-nowrap flex py-4 pr-3 text-sm font-medium', selectedPeople.includes(channel.channel_id) ? 'text-indigo-600' : 'text-gray-900']">

                    <div class="flex">
                      <div class="h-10 w-10 rounded-full bg-slate-400 bg-cover" :style="{
                        backgroundImage: channel.closest_channels && channel.closest_channels[0] && channel.closest_channels[0][3] && channel.closest_channels[0][3].thumbnails
                          ? `url(${channel.closest_channels[0][3].thumbnails})`
                          : ''
                      }"></div>

                      <svg class="h-6 w-6 -ml-2 mt-3" viewBox="0 0 36 36">
                        <path d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#444" stroke-width="4"
                          stroke-dasharray="75, 100" />
                      </svg>

                    </div>
                    <div class="ml-4 flex">
                      <div class="">
                        <span @click="currentMetadata = channel; showDetails = !showDetails">{{ channel.title }}


                          <!-- <span
                        class="inline-flex items-center rounded bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">{{
                        channel.gpt_treshold }}</span> -->
                        </span>
                        <br />
                        <span class="text-xs">{{ channel.gpt_classification_name }} | {{ channel.gpt_language }} | {{ channel.source }}
                          <ul v-if="channel.keywords" style="max-width: 300px; overflow-x: scroll;">
                            <span v-for="key in JSON.parse(channel.keywords)" :key="key"
                              class="inline-flex items-center rounded-md bg-gray-100 hover:bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 mr-2 cursor-pointer"
                              :class="{ 'hover:bg-green-300 bg-green-200 text-green-600': checkKeyword(key, currentCatogory) === 'isInList', ' hover:bg-red-300 bg-red-200 text-red-600': checkKeyword(key, currentCatogory) === 'otherCategory' }"
                              @click="currentCode = key, showAddLabelModal = true">
                              {{ key }}
                            </span>

                          </ul>
                        </span>
                      </div>
                      <!-- <div v-if="checkSimilar(channel)">
                    <button type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-slate-200 px-1.5 py-1 ml-2 text-xs font-semibold text-slate-500  hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="rgb(100, 116, 139)" class="w-4 h-4">
                        <path d="M11.983 1.907a.75.75 0 00-1.292-.657l-8.5 9.5A.75.75 0 002.75 12h6.572l-1.305 6.093a.75.75 0 001.292.657l8.5-9.5A.75.75 0 0017.25 8h-6.572l1.305-6.093z" />
                      </svg>
                    {{ checkSimilar(channel) }} similar
                  </button>

                  </div> -->

                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="flex flex-row" style="max-width: 70vw">
                      <div v-for="   videoId    in    JSON.parse(channel.video_ids).slice(0, 7)  " :key="videoId">
                        <div @click=" openVideoModal(videoId, channel.gpt_language, channel.title)"
                          class="w-24 h-14 mr-2 cursor-pointer bg-black rounded flex-none transform transition ease-in-out hover:scale-150"
                          :style="`background-image: url(https://img.youtube.com/vi/${videoId}/0.jpg); background-size: cover; background-position: center;`">
                        </div>
                      </div>
                      <button type="button"
                        class="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td class="whitespace-nowrap flex py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button @click="changeCategory(channel.channel_id, 'NONSAFE.TEMP')" type="button"
                      class="rounded-full bg-indigo-600 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">NONSAFE
                      TEMP</button>
                    <button @click="changeCategory(channel.channel_id, 'RECLASSIFY')" type="button"
                      class="rounded-full bg-red-600 px-2.5 ml-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">IDK</button>
                    <!-- <XCircleIcon class="h-5 w-5 cursor-pointer" @click=" remove(channel.channel_id) " /> -->
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="flex items-center justify-between border-t border-gray-200 bg-white py-3">
              <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                </div>
                <div>

                  <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <button type="button"
                      class="inline-flex mr-3 items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">

                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                      </svg>
                      Magic Channel Finder

                    </button>
                    <button type="button" @click=" showConfirmation = !showConfirmation"
                      class="ml-3 mr-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit
                      These {{ currentChannels.length >= 1 ? currentChannels.length : "" }}</button>

                    <a @click="prevPage" style="margin-left: 1rem;"
                      class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                      <span class="sr-only">Previous</span>
                      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"></path>
                      </svg>
                    </a>

                    <a @click="nextPage"
                      class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                      <span class="sr-only">Next</span>
                      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"></path>
                      </svg>
                    </a>
                  </nav>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import parseString from 'xml2js';
import axios from 'axios';
import NewDetails from './DetailsNew.vue';
import Details from './Details.vue';
import TagsWindow from './TagsWindow.vue';
import AddLabel from './AddLabel.vue';
import GraphView from './GraphView.vue';
import Confirmation from './Confirmation.vue';
import Notification from './notification.vue';
import WorkSessionModal from './WorkSessionModal.vue';
import VideoModal from './VideoModal.vue';
import IdleModal from './IdleModal.vue'
import IdleJs from 'idle-js';
import ProgressView from './ProgressView.vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon, PlayCircleIcon, XMarkIcon, PuzzlePieceIcon } from '@heroicons/vue/20/solid'
import { HandThumbDownIcon, ArchiveBoxXMarkIcon, QuestionMarkCircleIcon, ShieldCheckIcon, PlayIcon, XCircleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Multi-check',
  props: {
    taxonomy: Object,
    authToken: String,
    sub: String,
    user: String
  },
  components: {
    AddLabel,
    Notification,
    NewDetails,
    Details,
    VideoModal,
    IdleModal,
    Confirmation,
    Popover,
    PopoverButton,
    WorkSessionModal,
    ProgressView,
    TagsWindow,
    PopoverPanel,
    XMarkIcon,
    XCircleIcon,
    PlayIcon,
    PuzzlePieceIcon,
    ChevronDownIcon,
    GraphView
  },
  data() {
    return {
      showChannels: false,
      gettingWorkSessionToken: true,
      workSessionId: 0,
      time: 0,
      classificationsN: 0,
      inactive: false,
      showProgress: false,
      loading: false,
      showVideoModal: false,
      videoModal: {
        show: false,
        videoId: "",
        lang: "",
        channelName: ""
      },
      cursorMovement: {
        currentIndex: 0
      },
      video: false,
      heading: "",
      subline: "",
      openPanel: false,
      categories: {
        safe: { count: 0 },
        waste: { count: 0 },
        harmful: { count: 0 },
        rest: { count: 0 }
      },
      languages: [
        {
          name: "en"
        },
        {
          name: "nl"
        }
      ],
      options: [
        {
          name: 'Safe',
          description: 'Content auto classified as safe',
          type: 'safe',
          icon: ShieldCheckIcon,
        },
        {
          name: 'Non Safe/Suitable',
          description: 'Harmful sort of content, extremism, disinfo, etc.',
          type: 'harmful',
          icon: HandThumbDownIcon,
        },
        {
          name: 'Ad Waste',
          description: "Content classified as waste, such as kids content, music.",
          type: 'waste',
          icon: ArchiveBoxXMarkIcon
        },
        {
          name: 'Rest',
          description: "Content that couldn't be autoclassified",
          type: 'rest',
          icon: QuestionMarkCircleIcon,
        },
      ],
      callsToAction: [
        { name: 'Watch tutorial', href: '#', action: 'watchVideo', icon: PlayCircleIcon },
        { name: 'Difficult to Classify', href: '#', action: "openClassify", icon: PuzzlePieceIcon }
      ],
      deadline: "2025-01-01",
      token: "",
      tabs: [],
      tabsCategories: [],
      currentCatogory: "",
      currentPage: 0,
      limit: 20,
      currentChannels: [],
      selectedPeople: [],
      checked: false,
      currentMetadata: {},
      showDetails: false,
      showConfirmation: false,
      showNotification: false,
      showAddLabelModal: false,
      currentCode: "",
      allKeywords: [],
      allCodes: []
    }
  },
  computed: {
    indeterminate() {
      return this.selectedPeople.length > 0 && this.selectedPeople.length < this.channels.length
    },
    safeCount() { return this.categories.safe.count },
  },
  methods: {
    formatDate(date) {
      const pad = (n) => (n < 10 ? '0' + n : n);
      return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        ' ' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds())
      );
    },
    generateFutureDate(daysToAdd) {
      const currentDate = new Date();
      const futureDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
      return this.formatDate(futureDate);
    },
    async handleClick(action) {
      this.openPanel = false;
      if (action === 'openClassify') {
        await this.triggerCategory('RECLASSIFY');
      }

      if (action === 'watchVideo') {
        console.log('watch tutorial')
      }

    },
    async sendToStorage(arr, category) {

      function convertCode(value) {
        if (typeof value !== 'string') {
          return value;
        }

        const numberValue = parseFloat(value);

        if (!isNaN(numberValue) && value.includes('.') && !Number.isInteger(numberValue)) {
          return value;
        }

        if (!isNaN(numberValue) && !value.includes('.')) {
          return parseInt(value, 10);
        }

        return value;
      }

      let codes = [];
      codes.push(convertCode(category));

      let allChannels = [];

      for (const channel of arr) {
        let newestObj = {
          id: channel.channel_id,
          subscriberCount: 0,
          clientsGdi: "",
          checkedByName: this.user,
          checkedById: this.sub,
          taxonomy: codes ? codes : "",
          notesByReviewer: "",
          videoCount: 0,
          title: channel.title,
          gptExplanation: channel.gpt_explanation,
          videos: channel.ids,
          language: channel.gpt_language,
          description: ""
        }

        allChannels.push(newestObj);
      }


      try {
        const post = await axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/post-classification/id/${this.workSessionId}`, allChannels, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        if (arr.length === 1) {
          const newChannels = this.currentChannels.filter(object => object.channel_id !== arr[0].channel_id);
          this.currentChannels = newChannels;
          // this.classificationsN = this.classificationsN + 1;
        }
        this.onHideDetails();
        this.classificationsN = this.classificationsN + post.data.success.length;


        post.data.success.forEach((chan) => {
          const newChannels = this.currentChannels.filter(object => object.channel_id !== chan);
          this.currentChannels = newChannels;
        })

        // figure out somethign to hide just added ones

        return post;
      } catch (err) {
        console.log(err);
      }




    },
    async changeCategory(id, category) {
      const newChannels = this.currentChannels.filter(object => object.channel_id !== id);
      this.currentChannels = newChannels;

      try {
        await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/set-channel-reclassify/${id}/category/${category}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        this.heading = "Channel moved to reclassify"
        this.subline = `${id} has been moved to the Difficult to Classify queue.`

        this.showNotification = true;

        setTimeout(() => {
          this.showNotification = false;
        }, 3000);

      } catch (err) {
        console.log(err);
      }
    },
    async remove(id) {
      const newChannels = this.currentChannels.filter(object => object.channel_id !== id);
      console.log(newChannels);
      this.currentChannels = newChannels;

      try {
        await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/set-channel-reclassify/${id}/category/RECLASSIFY`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });


        this.heading = "Channel moved to reclassify"
        this.subline = `${id} has been moved to the Difficult to Classify queue.`

        this.showNotification = true;

        setTimeout(() => {
          this.showNotification = false;
        }, 3000);

      } catch (err) {
        console.log(err);
      }
    },
    onHideDetails() {
      this.showDetails = false;
      this.showVideoModal = false;
      this.showProgress = false;
      this.showAddLabelModal = false;
    },

    async submitToDatabase() {
      this.loading = true;
      const submit = await this.sendToStorage(this.currentChannels, this.currentCatogory)
      // this.classificationsN = this.classificationsN + submit.data.success.length;
      this.heading = "Channels added to database";
      this.subline = `${submit.data.success.length} channels added succesfully`
      this.loading = false;
      this.showConfirmation = false;
      this.showNotification = true;

      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
      this.nextPage();
    },
    chunkArray(array, size) {
      return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
        array.slice(i * size, i * size + size)
      );
    },
    async prevPage() {
      if (this.currentPage > 0) {
        this.cursorMovement.currentIndex = 0;
        this.currentPage--;
        this.currentChannels = await this.getChannelsDB(this.deadline, this.currentCatogory, this.limit, this.currentPage * this.limit);
        this.showChannels = true;

      }
    },
    async nextPage() {
      this.cursorMovement.currentIndex = 0;
      this.currentChannels = [];

      this.currentPage++;
      this.currentChannels = await this.getChannelsDB(this.deadline, this.currentCatogory, this.limit, this.currentPage * this.limit);
      this.showChannels = true;
    },
    openVideoModal(videoId, lang, channelName) {
      this.videoModal = {
        show: true,
        videoId: videoId,
        lang: lang,
        channelName: channelName
      }
      this.videoModal.show = true;

      this.showVideoModal = true;

      console.log(this.videoModal)

    },
    findName(code) {
      const group = this.taxonomy.find(item => item.code === code)
      return group ? group.title : code;
    },
    newTab(type) {
      this.tabs = [];


      this.categories[type].categories.forEach((category) => {
        this.tabs.push({
          name: this.findName(category.gpt_classification),
          id: category.gpt_classification,
          current: false,
          amount: category.count
        })
      })
      console.log('all tabs');
      console.log(this.taxonomy);

      console.log(this.tabs);


// Create a map for the taxonomy codes for quick lookups
let taxonomyMap = new Map();
this.taxonomy.forEach(item => {
    taxonomyMap.set(item.code, item);
});

// Then, iterate over tabs to build the desired structure
let result = {};
this.tabs.forEach(tab => {
    let taxonomy = taxonomyMap.get(tab.id);
    if (taxonomy) { // If a match is found in taxonomyMap
        // Find parent name in taxonomyMap
        let parent = taxonomyMap.get(taxonomy.parent_code);
        let parent_name = parent ? parent.title : "Rest";
        
        // If parent_code not in result yet, initialize it
        if (!result[taxonomy.parent_code]) {
            result[taxonomy.parent_code] = {
                name: parent_name,
                code: taxonomy.parent_code,
                count: 0, // Initialize count to 0
                sub_categories: []
            };
        }
        // Add tab to the sub_categories array
        result[taxonomy.parent_code].sub_categories.push(tab);
        // Update count
        result[taxonomy.parent_code].count += tab.amount;
    }
});

// Convert the result object to an array
result = Object.values(result);
this.tabsCategories = result;
// console.log(result);

    },
    divideCategories(arr) {
      const validCodes = this.taxonomy.map(item => item.code);

      const categories = arr.reduce((acc, cat) => {
        const foundItem = validCodes.includes(cat.gpt_classification)
          ? this.taxonomy.find(item => item.code === cat.gpt_classification)
          : null;

        let categoryName = 'rest';

        if (foundItem) {
          if (foundItem.ad_inventory_type === 'safe') {
            categoryName = 'safe';
          } else if (
            foundItem.ad_inventory_type === 'nonsafe' ||
            foundItem.ad_inventory_type === 'nonsuitable'
          ) {
            categoryName = 'harmful';
          } else if (foundItem.ad_inventory_type === 'waste') {
            categoryName = 'waste';
          }
        }

        acc[categoryName].count += cat.count;
        acc[categoryName].categories.push(cat);

        return acc;
      }, {
        safe: { count: 0, categories: [] },
        harmful: { count: 0, categories: [] },
        waste: { count: 0, categories: [] },
        rest: { count: 0, categories: [] },
      });

      this.categories = categories;
    },
    checkSimilar(channel) {
      if (!Object.prototype.hasOwnProperty.call(channel, 'closest_channels') || channel.closest_channels === null) {
        return false;
      } else {
        let n = 0;
        channel.closest_channels.forEach((chan) => {
          if (chan[1] > 0.75 && chan[2] === null) {
            n++;
          }
        });

        return n;
      }
    },
    newNetworkFindings(data) {
      let channelsUnclassified = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].closest_channels) {
          for (let n = 1; n < data[i].closest_channels.length; n++) {
            if (!data[i].closest_channels[n][2]) {
              channelsUnclassified.push(data[i].closest_channels[n])
            }
          }
        }
      }

      console.log('everything aggregated')
      console.log(channelsUnclassified)

      let result = {};

      channelsUnclassified.forEach(item => {
        let key = item[0];

        if (result[key]) {
          // If this key already exists in the result, increment the count and add the other values
          result[key][1] += item[1];  // Sum the second values (you might want to do something else here)
          result[key][4]++;  // Increment the count
        } else {
          // If this key doesn't yet exist in the result, add it with a count of 1
          result[key] = [...item, 1];  // Spread the original item and append the count
        }
      });

      // If you need the result in an array format, you can convert it:
      let arrayResult = Object.values(result);

      // Sort the array by the count
      console.log(arrayResult.sort((a, b) => b[4] - a[4]));
    },
    checkKeyword(name, categoryId) {
      name = name.toLowerCase();

      for (let i = 0; i < this.allCodes.length; i++) {
        if (this.allCodes[i].code === name && this.allCodes[i].taxonomy_code === categoryId) {
          return 'isInList';
        }
        if (this.allCodes[i].code === name && this.allCodes[i].taxonomy_code !== categoryId) {
          return 'otherCategory';
        }
      }
    },
    async getKeywordsCategory(id, codes) {
      try {
        let payload = {};

        if (id) {
          payload.taxonomy_code = id;
        }

        if (codes) {
          payload.codes = codes;
        }

        const { data } = await axios.post(
          `https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-keywords`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`
            }
          }
        );

        if (id) {
          this.allKeywords = data.map((d) => { return d.code; });
        }

        if (codes) {
          this.allCodes = data;
          console.log('all codes')
          console.log(this.allCodes);
        }

        console.log(data);

        return data
      } catch (err) {
        console.log(err)
      }
    },
    async triggerCategory(id) {
      this.cursorMovement.currentIndex = 0;
      this.allKeywords = [];
      this.currentPage = 0;
      this.currentCatogory = id;

      console.log(this.currentCatogory)
      this.currentChannels = [];
      this.showChannels = false;
      this.currentChannels = await this.getChannelsDB(this.deadline, id, this.limit, 0);
      this.showChannels = true;

      await this.getKeywordsCategory(id);


    },
    async getChannelsDB(date, taxonomy, limit, offset) {
  this.showChannels = false;

  try {
    const { data } = await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-channels-overview/${this.deadline}/${taxonomy}/${limit}/${offset}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    let allKeywords = [];

    console.log('get channels overview')
    console.log(data);

    data.forEach((d) => {
      if (d.keywords) {
        // Parse the keywords string to an array
        let keywordsArray = JSON.parse(d.keywords);
        console.log(keywordsArray)
        keywordsArray.forEach((keyword) => {
          let lowerCaseKeyword = keyword.toLowerCase();
          if (!allKeywords.includes(lowerCaseKeyword)) {
            allKeywords.push(lowerCaseKeyword);
          }
        })
      }
    })

    this.getKeywordsCategory(null, allKeywords);
    this.newNetworkFindings(data);

    return data
  } catch (err) {
    console.log(err)
  }
},
    async startWorkSession() {
      try {
        const { data } = await axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/create-work-session`, { name: this.user }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        console.log(data);
        this.gettingWorkSessionToken = false;
        this.workSessionId = data.work_session_id;
      } catch (err) {
        console.log(err);
      }
    },
    async getCategoriesDB() {
      try {
        const { data } = await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-overview/${this.deadline}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        this.divideCategories(data);
      } catch (err) {
        console.log(err);
      }
    },
    triggerCursorMovement() {
      let currentChannel = this.$refs.channelRefs[this.cursorMovement.currentIndex];

      // Get the y position of currentChannel here
      let yPos = currentChannel ? currentChannel.getBoundingClientRect().top + window.scrollY : 0;

      window.scrollTo({
        top: yPos - (96 * 2),  // Scroll to yPos
        behavior: 'smooth'
      });
    }
  },
  async mounted() {
    this.token = await this.$auth0.getAccessTokenSilently();

    // Start Work Session
    await this.startWorkSession();


    this.timer = setInterval(() => {
      this.time++;
    }, 1000);

    const idle = new IdleJs({
      idle: 600000, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: () => {
        this.inactive = true;
      },
      keepTracking: true, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: false // set it to true if you want to start in the idle state
    });
    idle.start();


    await this.getCategoriesDB();

    window.addEventListener('keydown', function (e) {
      if ([37, 38, 39, 40].includes(e.keyCode)) {
        e.preventDefault();
      }
    }, false);

    document.addEventListener('keydown', (event) => {
      switch (event.keyCode) {
        case 37:  // left key
          this.showDetails = false;
          break;
        case 38:  // If the UP key is pressed
          this.cursorMovement.currentIndex = this.cursorMovement.currentIndex === 0 ? 
            this.cursorMovement.currentIndex = 0 : 
            this.cursorMovement.currentIndex = this.cursorMovement.currentIndex -1;    
          this.triggerCursorMovement();
          break;
        case 39:  // Right key
          this.currentMetadata = this.currentChannels[this.cursorMovement.currentIndex]; 
          this.showDetails = true;
          break;
        case 40:  // If the DOWN key is pressed
          console.log(this.cursorMovement.currentIndex === this.currentChannels.length);
          this.cursorMovement.currentIndex = this.cursorMovement.currentIndex === this.currentChannels.length - 1? 
            this.cursorMovement.currentIndex = this.currentChannels.length - 1: 
            this.cursorMovement.currentIndex = this.cursorMovement.currentIndex + 1;    
          this.triggerCursorMovement()
          break;
      }
    });
  }
}
</script>

<style scoped>
.scrollbar-keywords::-webkit-scrollbar {
  display: none;
}

.hit {
  background: orange !important;
}
</style>