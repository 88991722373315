<template>
      <div class="relative" style="z-index: 10000;">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 as="h3" class="text-base font-semibold leading-6 text-gray-900">Work Session Expired</h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">You've been inactive for 10 minutes. Please refresh the page to start a new work session.</p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                  <button @click="refreshPage" type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto">Refresh</button>
                </div>
              </div>
          </div>
        </div>
      </div>
  </template>
  
  <script setup>
  import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

  const refreshPage = () => {
    window.location.reload();
  }
  
  </script>