<template>
  <div class="relative" style="z-index: 10000;" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">

      <div
        class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">

        <div class="h-12 flex justify-between">
          <div class="border-b pt-2 pl-6 border-gray-200">

          </div>
          <XMarkIcon @click="closeProgress" class="h-6 w-6 cursor-pointer mr-4 mt-4" />
        </div>

        <div class="flex divide-x divide-gray-100">
          <!-- Preview Visible: "sm:h-96" -->
          <div class="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 sm:h-96">
            <!-- Default state, show/hide based on command palette state. -->
            <h2 class="mb-4 mt-2 text-xs font-semibold text-gray-500">Progress View</h2>
            <ul class="-mx-2 text-sm text-gray-700" id="recent" role="listbox">
              <!-- Active: "bg-gray-100 text-gray-900" -->
              <li @click="menuOption = 'this_session'" class="group cursor-pointer flex items-center rounded-md p-2"
                id="recent-1" role="option" tabindex="-1">

                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <span class="ml-3 flex-auto truncate">This session (#{{ workSessionId }})</span>
                <!-- Not Active: "hidden" -->
                <svg class="ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
                </svg>
              </li>
            </ul>

            <!-- Results, show/hide based on command palette state. -->
            <ul class="-mx-2 text-sm text-gray-700" id="options" role="listbox">
              <!-- Active: "bg-gray-100 text-gray-900" -->
              <li class="group flex cursor-default items-center rounded-md p-2" id="option-1" role="option" tabindex="-1">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                  </svg>

                </div>
                <span class="ml-3 flex-auto truncate">Total Progress</span>
                <!-- Not Active: "hidden" -->
                <svg class="ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
                </svg>
              </li>
              <li @click="menuOption = 'work_sessions', getWorkSessions()" class="group flex cursor-pointer items-center rounded-md p-2"
                id="option-2" role="option" tabindex="-1">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                  </svg>

                </div>
                <span class="ml-3 flex-auto truncate">Previous Work Sessions</span>
                <svg class="ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
                </svg>
              </li>
              <li class="group flex cursor-default items-center rounded-md p-2" id="option-2" role="option" tabindex="-1">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                  </svg>


                </div>
                <span class="ml-3 flex-auto truncate">Predictions</span>
                <svg class="ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
                </svg>
              </li>

            </ul>
          </div>

          <!-- Active item side-panel, show/hide based on active state -->
          <div class="hidden h-96 w-2/3 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">

            <div v-if="menuOption === 'this_session'">

              <div
                class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Minutes Active</dt>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ parseInt(time
                  / 60) }}</dd>
              </div>

              <div
                class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Channels Classified</dt>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{
                  classificationsN }}</dd>
              </div>

              <div
                class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Seconds/Channel</dt>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ parseInt(time
                  / classificationsN) }}</dd>
              </div>

            </div>




            <div v-if="menuOption === 'work_sessions'">



              <div class="mt-6 px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-center">
                  <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">Work Sessions</h1>
                    <p class="mt-2 text-sm text-gray-700">This list contains a list of your work sessions. C means the amount of classifications done in the session and S/C means how many seconds it took you to do one classification on average. Time is in UTC.</p>
                  </div>
                </div>
                <div class="mt-8 flow-root">
                  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col"
                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Id</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Start</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">End</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">C</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">S/C</th>
                          </tr>
                        </thead>
                        <tbody v-if="workSessions.length" class="divide-y divide-gray-200">
                          <tr v-for="session in workSessions" :key="session.id">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">S{{ session.id }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ session.start_time.split('T')[0] }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ session.start_time.split('T')[1].split('.000')[0] }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ session.end_time.split('T')[1].split('.000')[0] }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ parseInt(session.total_seconds / 60) }} m.</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ session.total_classifications }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ session.total_seconds }}</td>
    
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/20/solid';
import axios from 'axios';

export default {
  components: {
    XMarkIcon
  },
  data() {
    return {
      menuOption: "this_session",
      workSessions: []
    }
  },
  props: {
    time: Number,
    classificationsN: Number,
    workSessionId: Number,
    token: String
  },
  methods: {
    closeProgress() {
      this.$emit('hide-details');
    },
    async getWorkSessions() {
      try {
        const { data } = await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-work-sessions`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        this.workSessions = data;

        // console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  }

}
</script>

<style></style>