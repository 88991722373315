<template>
  <div class="relative" style="z-index: 10000;">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 as="h3" class="text-base font-semibold leading-6 text-gray-900">Add {{ code }} to  {{taxonomySystem.find(item => item.code === taxonomy).title }} ({{ taxonomy }})
               
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Would you like to add keyword {{ code }} to {{ taxonomy }}? Click
                  confirm.</p>

                <div class="mt-4">
                  <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">Add a description
                    (optional)</label>
                  <div class="mt-2">
                    <textarea rows="4" name="description" id="description" v-model="description"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>

                <div class="mt-4">
                  <button type="button" @click="postKeywords"
                    class="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400">Add</button>
                  <button type="button" @click="hideDetails"
                    class="ml-2 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'AddLabel',
  props: {
    code: String,
    taxonomy: String,
    taxonomySystem: Array,
    token: String
  },
  data() {
    return {
      description: ""
    }
  },
  created() {
    window.addEventListener('keyup', this.checkEnterKey);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.checkEnterKey);
  },
  methods: {
    checkEnterKey(event) {
      if (event.keyCode === 13) {
        this.postKeywords();
      }
    },
    hideDetails() {
      this.$emit('hide-details');
    },
    async postKeywords() {
      try {
        const post = await axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/post-keywords`,
          {
            code: this.code.toLowerCase(),
            taxonomy_code: this.taxonomy,
            description: this.description
          }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });

        console.log(post);
        this.hideDetails()


      } catch (err) {
        console.log(err);
      }

    }
  }}
</script>