<template>
  <div class="fixed inset-0" style="z-index: 100">


    <div @click="hideDetails" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 overflow-hidden">

      <div class="fixed inset-0 flex justify-center items-center">
        <div class="w-2/5 h-4/5 overflow-y-scroll bg-white -ml-30 rounded-md shadow flex flex-col p-4"
          v-if="selectedChannel !== {} && selectedChannel[3]">
          <div class="flex border-b w-full border-gray-300 pb-4">
            <div class="h-20 w-20 rounded-full bg-black bg-cover" :style="{
                backgroundImage: selectedChannel[3].thumbnails
                  ? `url(${selectedChannel[3].thumbnails})`
                  : ''
              }"></div>
            <div class="ml-4 text-sm">
              <h3 class="text-lg mb-0 pt-4">{{ selectedChannel[3].title }}</h3>
              <span
                class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Similarity
                Score | {{ selectedChannel[1].toFixed(2) }}</span>
              <span v-if="!selectedChannel[2]"
                class="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Click
                to Add</span>
              <span v-if="selectedChannel[2]"
                class="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Already
                classified</span>
              <span v-if="selectedChannel[2]"
                class="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ returnName(selectedChannel[2]) }}</span>
            </div>
          </div>


          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Thumb
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>

                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="video in selectedChannelVideos.ids" :key="video.id">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">


                      <div class="bg-cover bg-center rounded border-gray-300 border"
                        :style="`background-image: url(https://img.youtube.com/vi/${video.id}/0.jpg); width: 135px; height: 67px;`">
                      </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ video.title }}</td>

                  </tr>
                </tbody>
              </table>
              {{ selectedChannelVideos }}
            </div>
          </div>
        </div>


      </div>

      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div class="pointer-events-auto relative w-96">
            <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
              <button type="button"
                class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                @click="hideDetails">
                <span class="sr-only">Close panel</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div class="h-full overflow-y-auto bg-white p-8">
              <div class="h-full overflow-y-auto bg-white p-8">
                <div class="space-y-6 pb-16">
                  <div>
                    <div class="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                      <img :src="`https://img.youtube.com/vi/${metadata.ids[0]}/0.jpg`" alt="" class="object-cover" />
                    </div>
                    <div class="mt-4 flex items-start justify-between">
                      <div>
                        <h2 class="text-base font-semibold leading-6 text-gray-900"><span class="sr-only">Details for
                          </span><a :href="`https://www.youtube.com/channel/${metadata.channel_id}`" target="blank">{{
                            metadata.title }}</a></h2>
                        <p class="text-sm font-medium text-gray-500">{{ metadata.gpt_explanation }}</p>
                      </div>

                    </div>
                  </div>
                  <div>
                    <h3 class="font-medium text-gray-900">Information</h3>
                    <dl class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                      <div class="flex justify-between py-3 text-sm font-medium">
                        <dt class="text-gray-500">Certainty Treshold</dt>
                        <dd class="text-gray-900">{{ metadata.gpt_treshold }}</dd>
                      </div>
                      <div class="flex justify-between py-3 text-sm font-medium">
                        <dt class="text-gray-500">Language</dt>
                        <dd class="text-gray-900">{{ metadata.gpt_language }}</dd>
                      </div>
                      <div class="flex justify-between py-3 text-sm font-medium">
                        <dt class="text-gray-500">Checked/Unchecked</dt>
                        <dd class="text-gray-900">{{ metadata.analysis_checked }}/{{ metadata.analysis_unchecked }}</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h3 class="font-medium text-gray-900">Classifications</h3>
                    <div class="mt-2 flex items-center justify-between">
                      <div id="main" class="w-full" style="width: 100%; height: 250px"></div>
                    </div>
                  </div>
                  <div>
                    <h3 class="font-medium text-gray-900">Closest Related Channels</h3>
                    <div class="mt-2 flex items-center justify-between relative" v-if="metadata.closest_channels"
                      style="height: 300px">
                      <div class="abosolute left-0 top-0 z-50">
                        <ul class="absolute left-0 top-0 z-50">

                          <li v-for="channel in closest_channels" :key="channel[0]"
                            class=" flex opacity-80 hover:opacity-100 cursor-pointer mb-0.5">
                            <div v-if="channel[3]" class="flex rounded-xl" :class="{ 'bg-slate-300 px-2 -mx-2': isItemInToBeAdded(channel[3].id) }">
                              <div class="h-6 w-6 rounded-full bg-black bg-cover" :style="{
                                  backgroundImage: channel[3].thumbnails
                                    ? `url(${channel[3].thumbnails})`
                                    : ''
                                }"></div>
                              <div @click="addToQueue(channel)"
                                @mouseenter="selectedChannel = channel, getRss(channel[3].id)" class="ml-2 pt-0.5 text-xs">{{
                                  channel[3].title }}
                                <span class="inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium"
                                  :class="{
                                    'text-red-700 bg-red-100': channel[1] < 0.7,
                                    'text-green-700 bg-green-100': channel[1] > 0.8,
                                    'text-purple-700 bg-purple-100': channel[1] >= 0.7 && channel[1] <= 0.8
                                  }">
                                  {{ channel[1].toFixed(2) }}
                                </span>

                                <span v-if="channel[2]" class="ml-2 inline-flex items-center rounded-md bg-gray-200 px-1.5 py-0.5 text-xs font-medium text-gray-600">{{ channel[2] }}</span>
                              </div>
                            </div>




                          </li>

                        </ul>

                      </div>
                    </div>
                    <button type="button"
                      class="rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">{{
                        toBeAdded.length }} channels </button> to be added in 
                      <v-select :options="taxonomy" label="title" class="w-full mt-2" v-model='multiSelect'></v-select>
                      <button @click="submitMultiple" type="button" class="mt-2 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>

                  </div>
                  <div>
                    <h3 class="font-medium text-gray-900">Reclassify this channel</h3>
                    <ul role="list" class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                      <li class="flex items-center justify-between py-3">
                        <v-select :options="taxonomy" label="title" class="w-full" v-model='selectedTaxonomy'></v-select>
                      </li>
                    </ul>
                  </div>
                  <div class="flex">
                    <button type="button" @click="reclassify()"
                      class="flex-1 text-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    <button type="button"
                      class="ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import taxonomy from '../data/new_taxonomy.json';
import axios from 'axios';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import parseString from 'xml2js';

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

export default {
  name: 'Details-View',
  props: {
    currentTaxonomy: String,
    showDetails: Boolean,
    metadata: Object,
    taxonomy: Array,
    user: String,
    sub: String,
    token: String
  },
  data() {
    return {
      open: true,
      multiSelect: {},
      selectedTaxonomy: {},
      selectedChannel: {},
      selectedChannelVideos: {},
      toBeAdded: [],
      closest_channels: []
    };
  },
  methods: {
    returnName(id) {
      return this.taxonomy.find((item) => item.code === id.toString()).title
    },
    isItemInToBeAdded(itemId) {
      // console.log(itemId)
      // console.log(this.toBeAdded.some(obj => obj.id === itemId))
      return this.toBeAdded.some(obj => obj.channel_id === itemId);
    },
    async checkClosest() {
      console.log(this.metadata.closest_channels)
      console.log('trigger')
      // console.log(array);
      let ids = this.metadata.closest_channels.map((a) => a[0])
      try {
        const {data} = await axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-classifications`, {'channels': ids}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        });


        let newArray = [];


        for (let n = 0; n < this.metadata.closest_channels.length; n++) {

          const id = this.metadata.closest_channels[n][0];
          const taxonomy = data.find((obj) => obj.id === id);

          if (taxonomy) {
            newArray.push([
              this.metadata.closest_channels[n][0],
              this.metadata.closest_channels[n][1],
              taxonomy.taxonomy[0],
              this.metadata.closest_channels[n][3]
            ])
          } else {
            newArray.push(this.metadata.closest_channels[n])
          }
          
        }


        this.closest_channels = newArray;
        // return array;
      } catch (err) {
        console.log(err)
      }

      
    },
    async getRss(id) {
      console.log('trigger')
      const response = await fetch(`https://proxy-rss.onrender.com/https://www.youtube.com/feeds/videos.xml?channel_id=${id}`);
      const data = await response.text();
      const xml = data;

      let obj = {};


      parseString.parseString(xml, (err, result) => {
        const entries = result.feed.entry;

        console.log(entries);

        obj = {
          name: result.feed.author[0].name[0],
          ids: entries?.map(entry => ({
            id: entry['yt:videoId'][0],
            title: entry['title'][0]
          })) ?? [],
        };
      });

      this.selectedChannelVideos = obj
    },
    addToQueue(obj) {
      if (obj[2]) {
        return false
      }

      let nObj = {
        channel_id: obj[3].id,
        title: obj[3].title,
        checkedById: this.sub,
        checkedByName: this.user,
        gptExplanation: "",
        videoCount: 0,
        videos: this.selectedChannelVideos.ids.map((id) => { return id.id }),
        taxonomy: [],
        notesByReviewer: "Added through detail panel",
        language: "",
        description: ""
      }

      const index = this.toBeAdded.findIndex(obj => obj.channel_id === nObj.channel_id);

      if (index !== -1) {
        // nObj is found in the array, remove it
        this.toBeAdded.splice(index, 1);
      } else {
        // nObj is not in the array, add it
        this.toBeAdded.push(nObj);
      }
      console.log(nObj)
    },
    submitMultiple() {
      if (this.multiSelect === {}) return false;

      this.$emit('submit-reclassify', this.toBeAdded, this.multiSelect.code);

    },
    reclassify() {
      if (this.selectedTaxonomy === {}) { return "" }
      this.$emit('submit-reclassify', [this.metadata], this.selectedTaxonomy.code);

      console.log(this.metadata);
      console.log(this.selectedTaxonomy.code);
    },
    hideDetails() {
      this.$emit('hide-details');
    },
    findName(code) {
      const group = this.taxonomy.find(item => item.code === code)
      return group ? group.title : code;
    },
    generateChart() {
      const totalCount = this.metadata.closest_classification.reduce((sum, item) => sum + item.count, 0);
      const unclassifiedCount = totalCount < 11 ? 11 - totalCount : 0;

      const data = this.metadata.closest_classification.map(item => ({
        value: item.count,
        name: `${this.findName(String(item.category))}`
      }));

      if (unclassifiedCount > 0) {
        data.push({
          value: unclassifiedCount,
          name: 'Unclassified'
        });
      }

      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          position: [10, 10]
        },
        legend: {
          show: false,
          top: '5%',
          left: 'center'
        },
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3'
        ],
        series: [
          {
            name: 'Classification',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      };

      option && myChart.setOption(option);

      let _this = this;

      myChart.on('click', function (params) {
        console.log(data[params.dataIndex]);

        const obj = _this.taxonomy.find((item) => item.title === data[params.dataIndex].name)
        _this.selectedTaxonomy = obj;
        console.log(_this.taxonomy);
        console.log(_this.selectedTaxonomy);
      });

    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.generateChart();
    })

    await this.checkClosest();

    if (Array.isArray(this.taxonomy)) {
  this.multiSelect = this.taxonomy.find((item) => item && item.code === this.currentTaxonomy);
} else {
  console.error('this.taxonomy is not an array:', this.taxonomy);
}  }
}
</script>
  
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}</style>