import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { createAuth0 } from '@auth0/auth0-vue';
import FloatingVue from 'floating-vue';
import {VueCsvImportPlugin} from "vue-csv-import";
import 'floating-vue/dist/style.css'

const app = createApp(App)
  .component('v-select', vSelect)
  .use(FloatingVue)
  .use(VueCsvImportPlugin)

app.use(
  createAuth0({
    domain: "auth.raditube.com",
    client_id: "Lf5I9EeIge1pSt7pNyMV32V51jr1glIQ",
    redirect_uri: window.location.origin,
    audience: "https://raditube.us.auth0.com/api/v2/"
  })
);

app.mount('#app')