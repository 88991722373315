<template>
  <div class="relative overflow-y-scroll" style="z-index: 200;">
    <div class="fixed inset-0" />

    <div class="fixed inset-0 overflow-scroll min-h-full">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <div>
            <div class="pointer-events-auto w-screen max-w-2xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div class="px-4 py-6 sm:px-6">
                  <div class="flex items-start justify-between">
                    <div class="text-base font-semibold leading-6 text-gray-900">Video Details</div>
                    <div class="ml-3 flex h-7 items-center">
                      <button type="button" @click="hideDetails()"
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                        <span class="sr-only">Close panel</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Main -->
                <div class="divide-y divide-gray-200">
                  <div class="pb-6">
                    <div class="h-10 bg-indigo-700 sm:h-10 lg:h-10" />
                    <div class="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                      <div>
                        <div class="-m-1 flex">
                          <div class="inline-flex overflow-hidden rounded-lg border-4 border-white">
                            <div class="flex-shrink-0 bg-cover bg-center" style="width: 320px; height: 180px;"
                              :style="{ backgroundImage: `url('https://img.youtube.com/vi/${videoId ? videoId : ''}/0.jpg')` }"
                              alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-6 sm:ml-6 sm:flex-1">
                        <div>
                          <div class="flex items-center">
                            <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">{{ title }}</h3>
                          </div>
                          <p class="text-sm text-gray-500">{{ channelName ? channelName : "" }}</p>
                        </div>
                        <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          <a :href="`https://dereferer.me/?https%3A//www.youtube.com/watch?v=${videoId ? videoId : ''}`" target="_blank"
                            rel="noopener noreferrer">
                            <button type="button"
                              class="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1">View
                              on YouTube</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 py-5 sm:px-0 sm:py-0">
                    <dl class="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <div class="sm:flex sm:px-6 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          Description</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0 max-h-16 overflow-scroll">
                          <p>{{ description }}
                          </p>
                        </dd>
                      </div>
                      <div class="sm:flex sm:px-6 sm:py-5">
                        <dt class="text-sm flex font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          Transcripts
                          <ClipboardDocumentIcon 
                            @click="copy(transcripts)" 
                            class="ml-2 h-5 w-5 cursor-pointer" />
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0 max-h-24 overflow-scroll">

                          <svg aria-hidden="true" v-if="loading"
                            class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor" />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>

                          <p>{{ transcripts }}</p>
                        </dd>
                      </div>
                      <div class="sm:flex sm:px-6 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          Prompt Option</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">

                          <Listbox as="div" v-model="selected">
                            <ListboxLabel class="sr-only">Change published status
                            </ListboxLabel>
                            <div class="relative">
                              <div class="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                                <div
                                  class="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  <p class="text-sm font-semibold">{{ selected.title
                                  }}</p>
                                </div>
                                <ListboxButton
                                  class="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                                  <span class="sr-only">Change published status</span>
                                  <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                </ListboxButton>
                              </div>


                              <ListboxOptions
                                class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <ListboxOption as="template" v-for="option in publishingOptions" :key="option.title"
                                  :value="option" v-slot="{ active, selected }">
                                  <li
                                    :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none p-4 text-sm']">
                                    <div class="flex flex-col">
                                      <div class="flex justify-between">
                                        <p :class="selected ? 'font-semibold' : 'font-normal'">
                                          {{ option.title }}</p>
                                        <span v-if="selected" :class="active ? 'text-white' : 'text-indigo-600'">
                                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      </div>
                                      <p :class="[active ? 'text-indigo-200' : 'text-gray-500', 'mt-2']">
                                        {{ option.description }}</p>
                                    </div>
                                  </li>
                                </ListboxOption>
                              </ListboxOptions>
                            </div>
                          </Listbox>


                        </dd>
                      </div>
                      <div class="sm:flex sm:px-6 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          Prompt</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0" style="width: 100%;">

                          <form action="#" style="width: 100%;">
                            <TabGroup v-slot="{}">
                              <TabList class="flex items-center">
                                <Tab as="template" v-slot="{ selected }">
                                  <button
                                    :class="[selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900', 'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium']">Write</button>
                                </Tab>
                                <Tab as="template" v-slot="{ selected }">
                                  <button
                                    :class="[selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900', 'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium']">Preview</button>
                                </Tab>

                              </TabList>
                              <TabPanels class="mt-2">
                                <TabPanel class="-m-0.5 rounded-lg p-0.5">
                                  <label for="comment" class="sr-only">Comment</label>
                                  <div>
                                    <textarea rows="5" name="comment" id="comment"
                                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      placeholder="Add your comment..." />
                                  </div>
                                </TabPanel>
                                <TabPanel class="-m-0.5 rounded-lg p-0.5">
                                  <div class="border-b">
                                    <div class="mx-px mt-px px-3 pb-12 pt-2 text-sm leading-5 text-gray-800">
                                      Preview content will render here.</div>
                                  </div>
                                </TabPanel>
                              </TabPanels>
                            </TabGroup>
                            <div class="mt-2 flex justify-end">
                              <button type="submit"
                                class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit
                                to GPT</button>
                            </div>
                          </form>



                        </dd>
                      </div>

                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import axios from 'axios';

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    XMarkIcon,
    CheckIcon,
    ChevronDownIcon,
    ClipboardDocumentIcon
  },
  data() {
    return {
      loading: true,
      title: "",
      description: "",
      transcripts: "",
      publishingOptions: [
        {
          title: 'Generate Summary',
          description: 'This will generate a summary based on the transcripts',
          current: true,
        },
        {
          title: 'Thematic Matching',
          description: 'See if keywords of this category are matching.',
          current: false,
        },
      ],
      selected: null,
    };
  },
  props: {
    videoId: String,
    channelName: String,
    token: String,
    lang: String,
    open: Boolean
  },
  async created() {
    this.selected = this.publishingOptions[0];
    await this.fetchTranscripts();
  },
  methods: {
    hideDetails() {
      this.$emit('hide-details');
    },
    copy(text) {
      navigator.clipboard.writeText(text);
    },
    async fetchTranscripts() {
      try {
        const { data } = await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-transcripts/${this.videoId}/language/${this.lang}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        })
        console.log(data);

        this.description = data.description;
        this.transcripts = data.subtitles;
        this.title = data.title;
        this.loading = false;
      } catch (err) {
        console.log(err)
      }
    }
  }
};
</script>