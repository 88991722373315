<template>
 
<div class="relative z-50" role="dialog" aria-modal="true">
  
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">

    <div class="mx-auto max-w-5xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
      <div class="relative p-4">
        <span @click="currentView = 'videos'" class="cursor-pointer inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-2" :class="{ 'bg-green-200': currentView === 'videos' }">
          <span class="px-2 py-1 bg-white text-xs font-bold mr-2">1</span> Videos
        </span>
        <span @click="currentView = 'network'" class="cursor-pointer inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-2" :class="{ 'bg-green-200': currentView === 'network' }">
          <span class="px-2 py-1 bg-white text-xs font-bold mr-2">2</span> Network
        </span>
        <span @click="currentView = 'metadata'" class="cursor-pointer inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-2" :class="{ 'bg-green-200': currentView === 'metadata' }">
          <span class="px-2 py-1 bg-white text-xs font-bold mr-2">3</span> Metadata
        </span>
      </div>

      <div class="flex divide-x divide-gray-100">
        <div class="min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 sm:h-96" style="min-height: 70vh">
        <div class="flex w-full items-center border-b -mx-4 px-4 pb-2" style="width: calc(100% + 2rem)">
          <div class="h-12 w-12 rounded-full bg-black"></div>
          <div class="flex flex-col ml-2">
          <h2 class="text-lg">{{ channelMetadata.title }}</h2>
          <h3 class="text-xs font-semibold">{{ channelMetadata.gpt_explanation }}</h3>
          </div>
        </div>

        <div v-if="currentView === 'metadata'" class="">
          <!-- Default state, show/hide based on command palette state. -->
          <h2 class="mb-4 mt-2 text-xs font-semibold text-gray-500">Metadata</h2>

          <div>
            <h3 class="font-medium text-gray-900">Information</h3>
            <dl class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
              <div class="flex justify-between py-3 text-xs font-medium">
                <dt class="text-gray-500">Certainty Treshold</dt>
                <dd class="text-gray-900">{{ channelMetadata.gpt_classification_name }} ({{ channelMetadata.gpt_treshold }})</dd>
              </div>
              <div class="flex justify-between py-3 text-xs font-medium">
                <dt class="text-gray-500">Language</dt>
                <dd class="text-gray-900">{{ channelMetadata.gpt_language }}</dd>
              </div>
              <div class="flex justify-between py-3 text-xs font-medium">
                <dt class="text-gray-500">Tags</dt>
                <dd class="text-gray-900">
                  <li v-for="tag in JSON.parse(channelMetadata.keywords)" :key="tag">
                    <span  v-html="highlightKeywords(tag)"></span>
                  </li>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <!-- Preview Visible: "sm:h-96" -->
        <div v-if="currentView === 'network'">
          <!-- Default state, show/hide based on command palette state. -->
          <h2 class="mb-4 mt-2 text-xs font-semibold text-gray-500">Network</h2>
          <ul class="-mx-2 text-sm text-gray-700 ml-1" id="recent" role="listbox">
            
            <li 
              v-for="channel in JSON.parse(channelMetadata.closest_channels)" :key="channel[0]" 
              :class="{ 'bg-gray-100': checkNetworkInCategory(channel[0])}"
              class="flex mb-2 box-border rounded">
              <a :href="`https://anonym.es/?https://www.youtube.com/channel/${channel[0]}`" target="_blank" class="flex mb-2">
               <div
                class="w-9 h-9 mr-2 cursor-pointer bg-black rounded flex-none"
                :style="`background-image: url(${channel[0][2].thumbnails}); background-size: cover; background-position: center;`">
              </div>
              <span class="inline-flex items-center rounded px-1.5 py-0.5 text-xs font-medium mx-1"
                :class="{
                  'text-red-700 bg-red-100': channel[0][1] < 0.7,
                  'text-green-700 bg-green-100': channel[0][1] > 0.8,
                  'text-purple-700 bg-purple-100': channel[0][1] >= 0.7 && channel[0][1] <= 0.8
                }">
                {{ channel[0][1].toFixed(2) }}
              </span>
              <h3 class="text-sm" v-html="highlightKeywords(channel[0][2].title)"></h3>
            </a>
            </li>
          </ul>
        </div>

        <div v-if="currentView === 'videos'">
          <!-- Default state, show/hide based on command palette state. -->
          <h2 class="mb-4 mt-2 text-xs font-semibold text-gray-500">Videos</h2>
          <ul class="-mx-2 text-sm text-gray-700" id="recent" role="listbox">
            <li v-for="video in viewMetadata.videos" :key="video.id" class="flex mb-2">
              <div
                class="w-16 h-9 mr-2 cursor-pointer bg-black rounded flex-none transform transition ease-in-out hover:scale-150"
                :style="`background-image: url(https://img.youtube.com/vi/${video.id}/0.jpg); background-size: cover; background-position: center;`">
              </div>
              <div class="flex flex-col">
                <h3 class="text-sm cursor-pointer" @click="showMeta = !showMeta" v-html="highlightKeywords(video.title)"></h3>
                <p class="text-xs" v-if="showMeta" v-html="highlightKeywords(video.description)"></p>
                <a :href="`https://anonym.es/?https://www.youtube.com/watch?v=${video.id}`" target="_blank" class="text-xs font-semibold" v-if="showMeta">Go to video</a>
              </div>
            </li>
          </ul>
        </div>
      </div>

        <!-- Active item side-panel, show/hide based on active state -->
        <div class="hidden w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex" style="min-height: 100%;">
          <div v-if="loading">Loading</div>
          <div class="flex-none p-6">
            <button 
              v-for="(choice, index) in choices" :key="choice.id"
              type="button" 
              @click="active = index, removeListener(choice.code)"
              class="flex align-left mt-2 w-full rounded-md px-3 py-2 text-sm font-semibold text-lg text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              :class="{ 'bg-gray-400': index !== active, 'bg-indigo-600': index === active }">
              <span class="bg-white rounded px-2 text-black mr-2">{{ hotkeys[index] }}</span>
              
              <span class="mr-2" v-if="choice.code !== 'OTHER'">{{choice.title}}</span>
              <span class="mr-2" v-if="choice.code === 'OTHER'">
                <v-select :options="taxonomy" label="title" class="w-full" style="width: 300px" v-model='selectedTaxonomy'></v-select>
              </span>
              <span v-if="choice.networkStrength > 0" class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-2 cursor-pointer">👥 {{ choice.networkStrength }}/{{ choice.networkSize }}</span>
            </button>

            <button @click="addToInterface()" type="button" class="mt-2 rounded-full bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit (or press ENTER)</button>


          </div>

        </div>
      </div>

    </div>
  </div>
</div>

</template>

<script>
import parseString from 'xml2js';
import axios from 'axios';



// <NewDetails v-if="true" id="UCbmDmawYt5bSV9UT6OF4fAQ" :taxonomy="taxonomy" :token="token" :user="user"
//       :sub="sub" @hide-details="onHideDetails" @submit-reclassify="sendToStorage"></NewDetails>


export default {
  name: 'DetailsNew',
  props: {
    token: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true
    },
    taxonomy: {
      type: Array,
      required: true
    },
    channelMetadata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selectedTaxonomy: {},
      hotkeys: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
      currentView: 'videos',
      viewMetadata: {
        videos: [],
        network: [],
        metadata: {}
      },
      toBeAdded: [],
      active: 0,
      showMeta: false,
      allKeywords: [],
      choices: [
        {
          title: 'Test',
          code: '34',
          networkStrength: 8,
          networkSize: 12,
          tags: ['blabla', 'jaja'],
          channelIds: []
        }
      ]
    }
  },
  methods: {
    highlightKeywords(title) {
      if (this.allKeywords.length === 0) {
        return title;
      }
      let highlightedTitle = title;
      this.allKeywords[this.active].forEach(tag => {
        const re = new RegExp(`\\b${tag.code}\\b`, "gi"); // 'i' flag for case-insensitive matching
        if (re.test(title)) {
          highlightedTitle = highlightedTitle.replace(re, match => `<span class="highlight">${match}</span>`);
        }
      });
      return highlightedTitle;
    },
    async getKeywordsCategory() {
      const ids = this.choices.map((c) => {return c.code;});

      for (let i = 0; i < ids.length; i++) {
        try {
          let payload = {};
  
          payload.taxonomy_code = ids[i];
  
          const { data } = await axios.post(
            `https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-keywords`,
            payload,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`
              }
            }
          );

          this.allKeywords.push(data);
        } catch (err) {
          console.log(err)
        }

      }

      // this.allKeywords = fullResults;
      console.log(this.allKeywords);

    },
    checkNetworkInCategory(id) {
      if (this.choices[this.active].channelIds.includes(id)) {
          console.log(true)
          return true
      } else {
          console.log(false)
          return false
      }
    },
    reclassify(code) {
      this.$emit('submit-reclassify', [this.channelMetadata], code);
    },
    async addToInterface() {
      if (this.choices[this.active].code === 'RECLASSIFY' || this.choices[this.active].code === 'NONSAFE.TEMP') {
        console.log('reclassify')
        this.reclassify(this.choices[this.active].code);
        return;
      }

      this.toBeAdded = [];

      let nObj = {
        channel_id: this.channelMetadata.channel_id,
        title: this.channelMetadata.title,
        checkedById: this.sub,
        checkedByName: this.user,
        gptExplanation: this.channelMetadata.gpt_explanation,
        videoCount: 0,
        videos: this.viewMetadata.videos.map((id) => { return id.id }),
        taxonomy: this.choices[this.active].code === 'OTHER' ?  [this.selectedTaxonomy.code] : [this.choices[this.active].code],
        language: this.channelMetadata.gpt_language,
        description: ""
      }

      this.toBeAdded.push(nObj);
      
      console.log(this.toBeAdded)

      // do submit multiple
      this.submitMultiple();
    },
    submitMultiple() {
      if (this.choices[this.active].code === 'OTHER' && this.selectedTaxonomy === {}) return false;
      const code = this.choices[this.active].code === 'OTHER' ?  this.selectedTaxonomy.code : this.choices[this.active].code
      this.$emit('submit-reclassify', this.toBeAdded, code);
    },
    returnName(id) {
      const item = this.taxonomy.find((item) => item.code === id.toString());
      const name = item ? item.title : "CT.0";
      return name;
    },
    async checkClosest() {
    let closestChannels = JSON.parse(this.channelMetadata.closest_channels);
    let ids = closestChannels.map((a) => a[0])

    try {
        const { data } = await axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-classifications`, { 'channels': ids }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`
            }
        });

        let groupedChannels = {};

        for (let n = 0; n < closestChannels.length; n++) {
          const id = closestChannels[n][0][0];
            const taxonomy = data.find((obj) => obj.channel_id === id);

            if (taxonomy) {
                let taxonomyValue = taxonomy.taxonomy[0];

                if (!groupedChannels[taxonomyValue]) {
                    groupedChannels[taxonomyValue] = {
                      title: this.returnName(taxonomyValue),
                      code: taxonomyValue,
                      networkStrength: 1,
                      networkSize: closestChannels.length,
                      tags: [''],
                      channelIds: [id]
                    };
                } else {
                    groupedChannels[taxonomyValue].networkStrength++;
                    groupedChannels[taxonomyValue].channelIds.push(id);
                }
            }
        }

        this.loading = false;

        // Convert object values to an array
        let newArray = Object.values(groupedChannels);
        newArray = newArray.sort((a, b) => b.networkStrength - a.networkStrength);


        newArray.push({
          title: 'Possibly harmful',
          code: 'NONSAFE.TEMP',
          networkStrength: 0,
          networkSize: closestChannels.length,
          tags: [],
          channelIds: []
        })

        newArray.push({
          title: 'I dont know',
          code: 'RECLASSIFY',
          networkStrength: 0,
          networkSize: closestChannels.length,
          tags: [],
          channelIds: []
        })

        newArray.push({
          title: 'Other Category',
          code: 'OTHER',
          networkStrength: 0,
          networkSize: closestChannels.length,
          tags: [],
          channelIds: []
        })


        this.choices = newArray;

        this.getKeywordsCategory();
        // console.log(newArray);

      } catch (err) {
        console.log(err)
      }
    },
    removeListener(type) {
      if (type === 'OTHER') {
        window.removeEventListener('keydown', this.keyDownHandler);
        document.removeEventListener('keydown', this.documentKeyDownHandler);
      }
    },
    async getRss(id) {
    try {
      const response = await fetch(`https://proxy-rss.onrender.com/https://www.youtube.com/feeds/videos.xml?channel_id=${id}`);
      
      if (!response.ok) { throw new Error('Network response was not ok'); }

      const data = await response.text();
      const xml = data;
      let obj = {};

      parseString.parseString(xml, (err, result) => {
        if (err) {
          throw new Error('Failed to parse XML');
        }

        const entries = result.feed.entry;


        obj = {
          name: result.feed.author[0].name[0],
          ids: entries?.map(entry => ({
            id: entry['yt:videoId'][0],
            title: entry['title'][0],
            description: entry['media:group'][0]['media:description'][0]
          })) ?? [],
        };
      });

      this.viewMetadata.videos = obj.ids;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  },
  },
  async mounted() {
    console.log('mounted modal')
    console.log(JSON.parse(this.channelMetadata.closest_channels));
    // console.log(this.id);
    await this.getRss(this.channelMetadata.channel_id);

    this.keyDownHandler = function (e) {
      const keyMapping = {
        81: 0, // Q
        87: 1, // W
        69: 2, // E
        82: 3, // R
        84: 4, // T
        89: 5, // Y
        85: 6, // U
        73: 7, // I
        79: 8, // O
        80: 9  // P
      };

      const keyCode = e.keyCode;
      if ([13, 49, 50, 51].concat(Object.keys(keyMapping).map(Number)).includes(keyCode)) {
        e.preventDefault();
      }

      if (keyCode in keyMapping) {
        this.active = keyMapping[keyCode];
      }
    }.bind(this);

    this.documentKeyDownHandler = (event) => {
      switch (event.keyCode) {
        case 13:  // enter
          this.addToInterface();
          // enter
          break;
        case 49:  // 1
          this.currentView = 'videos';
          break;
        case 50:  // 2
          this.currentView = 'network';
          break;
        case 51: // 3
          this.currentView = 'metadata';
          break;
      }
    };

    window.addEventListener('keydown', this.keyDownHandler, false); 
    document.addEventListener('keydown', this.documentKeyDownHandler);

    this.checkClosest();
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyDownHandler);
    document.removeEventListener('keydown', this.documentKeyDownHandler);
  }



  }

</script>

<style>
.highlight {
  background: #4E46DC;
  color: white;
  border-radius: 2px;
  padding: 0 3px;
}

.vs__search {
  color: black  !important;
}

.vs__dropdown-toggle, .v-select {
  background: white;
}

.vs__dropdown-option {
  color: black !important;
}
</style>