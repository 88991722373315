<template>
  <div :class="{ 'dark-mode': bgStatus }">
    <div class="fixed z-20 left-2 top-2 text-xs bg-gray-300 rounded px-3"
      v-if="isAuthenticated && !startScreen && !showMulticheck">{{ initialPos + 1 }} / {{ queue.length }}</div>
    <div class="fixed z-20 rounded-l h-6 w-6 bg-gray right-10 border border-black top-2" @click="clickHotKey(true)">M
    </div>

    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" v-if="showHotKeyMenu">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

          <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Set a hot key</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Set a hot key. Provide both the taxonomy code and the name.</p>
                    <div class="sm:col-span-3">
                      <label for="taxonomy-code" class="block text-sm font-medium leading-6 text-gray-900">Taxonomy
                        Code</label>
                      <div class="mt-2">
                        <input type="text" v-model="hotkeys.code" name="taxonomy-code" id="taxonomy-code"
                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label for="taxonomy-name" class="block text-sm font-medium leading-6 text-gray-900">Taxonomy
                        Name</label>
                      <div class="mt-2">
                        <input type="text" v-model="hotkeys.name" name="taxonomy-name" id="taxonomy-name"
                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click="activeHotKeys(); showHotKeyMenu = !showHotKeyMenu" type="button"
                class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto">Activate
                Hot Key</button>
              <button @click="showHotKeyMenu = !showHotKeyMenu" type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="fixed z-20 rounded-full h-6 w-6 bg-black right-2 top-2" @click="bgMode(true)"></div>
    <div class="min-h-screen" v-if="startScreen && isAuthenticated">

      <ul class="h-screen flex flex-col items-center justify-center">
        <a href="#" @click="initialOption = 'multiclassifier', startScreen = false"
          class="block max-w-sm p-6 mb-3 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Multi-classifier</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">The new multi-classifier, retrieves channels from the database.</p>
        </a>



        <a href="#" @click="initialOption = 'csv'"
          class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">CSV</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">Use CSV file, this requires the headers channelid and
            score.</p>

          <vue-csv-import v-if="initialOption === 'csv'" v-model="csv" :fields="{
            channelId: { required: true, label: 'channelId' },
            harmfulnessAuto: { required: true, label: 'harmfulnessAuto' },
            autoFirstRaditubeTaxonomy: { required: true, label: 'autoFirstRaditubeTaxonomy' },
            otherAutoTaxonomy: { required: true, label: 'otherAutoTaxonomy' },
            notes: { required: true, label: 'notes' }
          }">
            <vue-csv-errors></vue-csv-errors>
            <vue-csv-input></vue-csv-input>
            <vue-csv-map :auto-match="true"></vue-csv-map>
          </vue-csv-import>
        </a>
        <a href="#" @click="initialOption = 'json'"
          class="mt-4 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">JSON w/ GPT</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">Use JSON file with pre-rendered GPT enhancement</p>
          <div v-if="initialOption === 'json'">
            <input type="file" @change="uploadJson" accept=".json" />
            <button @click="submitFile" :disabled="!selectedFile">Upload</button>
          </div>
        </a>
        <a href="#" @click="initialOption = 'list'"
          class="mt-4 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Channel List</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">Drop a list as an array ["ID", "ID2"] </p>

          <div v-if="initialOption === 'list'">
            <textarea v-model="channelIds" placeholder="add multiple lines"></textarea>
            <button @click="submitList(channelIds)">Submit</button>
          </div>
        </a>
      </ul>
    </div>

    <div v-if="loading" class="flex h-screen w-screen items-center justify-center absolute opacity-90 backdrop-blur-3xl">
      <div class="text-gray-600">
        <div class="spinner border-2 border-gray-800"></div>
        <p class="pt-2">Please wait... System is checking whether this channel has been classified</p><br />
        <p class="pt-2" @click="nextChannel()">Click here to retry if this screen looks stuck</p>
      </div>
    </div>

    <Multicheck v-if="isAuthenticated && initialOption == 'multiclassifier'" :authToken="authToken" :taxonomy="taxonomy" :user="user.name"
      :sub="user.sub"></Multicheck>

    <Interface v-if="isAuthenticated && !startScreen && firstCheck" :currentId="toBeCheckedQueue[toBeCheckedPos]"
      :key="toBeCheckedQueue[toBeCheckedPos]" :metadata="getMetadata(toBeCheckedQueue[toBeCheckedPos])"
      :customHotKeyBool="activateHotKeys" :hotkeys="hotkeys" @next-channel="nextChannel" :user="user.name"
      :sub="user.sub" />
    <div class="border-t" v-if="isAuthenticated">

      <a href="#" class="group block flex-shrink-0 m-4">
        <div class="flex items-center">
          <div>
            <img class="inline-block h-9 w-9 rounded-full" :src="user.picture" alt="" />
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{ user.name }}</p>
            <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{ user.email }}</p>
          </div>
        </div>
      </a>
    </div>
    <button v-if="!isAuthenticated" @click="login" class="text-xl">Log in</button>
  </div>
</template>

<script>
import Interface from "./components/Interface.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import axios from "axios";
import Multicheck from './components/MulticheckDynamic.vue';

// import taxonomyData from "./data/new_taxonomy.json";

export default {
  components: {
    Interface,
    Multicheck,
  },
  data() {
    let initialPos = Math.floor(Math.random() * 300);

    return {
      taxonomy: {},
      firstCheck: false,
      // showMulticheck: false,
      checkAgainIn: 5,
      bgStatus: false,
      // startScreen: false,
      startScreen: true,
      loading: false,
      initialOption: "",
      csv: {},
      channelIds: "",
      metadata: [],
      authToken: "",
      initialPos: initialPos,
      toBeCheckedQueue: [],
      toBeCheckedPos: 0,
      queue: ["UCIGboOQD6VVCmGSyfe-VLvA", "UCQo44A5hgTzafqF3dZvYYDw", "UCkY8CvV8WQFe87CZGmvuYHA"],
      token: "",
      prevTimestamp: 0,
      newTimestamp: 0,
      showHotKeyMenu: false,
      activateHotKeys: false,
      hotkeys: {
        code: "",
        name: "",
      }
    };
  },
  watch: {
    csv: function (val) {
      this.submitCsv(val)
    }
  },
  setup() {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();

    return {
      login: () => {
        loginWithRedirect();
      },
      user,
      isAuthenticated,
    };
  },
  methods: {
    bgMode(bool) {
      if (bool) {
        this.bgStatus = !this.bgStatus
      }
    },
    uploadJson(event) {
      const file = event.target.files[0];
      if (!file) {
        alert('Please select a JSON file.');
        return;
      }

      const reader = new FileReader();
      reader.onload = e => {
        try {
          console.log(JSON.parse(e.target.result))
          this.csv = JSON.parse(e.target.result)

          this.submitCsv(JSON.parse(e.target.result))
        } catch (error) {
          console.error(error);
          alert('An error occurred while reading the JSON file.');
        }
      };
      reader.readAsText(file);
    },
    async checkChannels(channelArray) {
      try {
        const dataObj = {
          channelIds: channelArray,
          metadata: false
        };

        const { data } = await axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/classification-check-if-indexed', dataObj, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
          }
        })

        return data;
      } catch (err) {
        console.log(err)
      }
    },
    // cleanArray(item) {
    //   const index = this.queue.indexOf(item);
    //     if (index > -1) {
    //       this.queue.splice(index, 1);
    //     }
    // },
    getMetadata(chanId) {
      const foundObj = this.csv.find(obj => obj.channelId === chanId);
      return foundObj;
    },
    async checkNextChannels() {
      const batchSize = 10;
      const remainingIds = this.queue.length - this.initialPos;
      const queryIds = this.queue.slice(this.initialPos, this.initialPos + Math.min(batchSize, remainingIds));
      this.initialPos = this.initialPos + queryIds.length;

      const newIds = await this.checkChannels(queryIds)

      newIds.unindexed.forEach((id) => this.toBeCheckedQueue.push(id))

      this.firstCheck = true;
    },
    convertIdsToStructure(ids) {
      return ids.map(id => ({
        channelId: id,
        harmfulnessAuto: "",
        autoFirstRaditubeTaxonomy: "",
        otherAutoTaxonomy: [
          ""
        ],
        notes: ""
      }));
    },
    async submitList() {
      const ids = JSON.parse(this.channelIds);
      this.csv = this.convertIdsToStructure(ids);
      await this.submitCsv(this.csv);
    },
    async nextChannel(type, classification) {
      this.toBeCheckedPos = this.toBeCheckedPos + 1;

      let currentSeconds = Math.floor(new Date().getTime() / 1000);

      if (this.prevTimestamp === 0) {
        this.prevTimestamp = currentSeconds
      } else if (this.prevTimestamp !== 0) {
        this.prevTimestamp = currentSeconds;
      }

      console.log(classification);



      if (this.toBeCheckedQueue.length - this.toBeCheckedPos <= 5 && this.queue.length - this.initialPos !== 0) {
        await this.checkNextChannels();
      }
    },
    shuffleArr(array) {
      // for (let i = array.length - 1; i > 0; i--) {
      //   const j = Math.floor(Math.random() * (i + 1));
      //   [array[i], array[j]] = [array[j], array[i]];
      // }
      return array;
    },
    clickHotKey(bool) {
      console.log(bool)
      this.showHotKeyMenu = !this.showHotKeyMenu;
    },
    activeHotKeys() {
      this.activateHotKeys = true;
    },
    async submitCsv(arr) {
      this.authToken = await this.$auth0.getAccessTokenSilently()

      console.log(arr);

      this.queue = [];
      this.shuffleArr(arr).forEach((channel) => {
        if (channel.channelId !== 'channelId') {
          this.queue.push(channel.channelId)
          this.metadata.push(channel);
        }

      })
      this.initialPos = 0;
      this.startScreen = false;

      this.loading = true;
      await this.checkNextChannels()
      this.loading = false;
    }
  },
  async mounted() {
    this.authToken = await this.$auth0.getAccessTokenSilently()

    try {
        const {data} = await axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-taxonomy`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
          }
        });

        this.taxonomy = data.results;

       console.log(data.results);

      } catch (err) {
        console.log(err);
      }
  }

};
</script>

<style>
@import url('./assets/fonts.css');

html {
  scroll-behavior: smooth;
}

.dark-mode {
  background: #262626;
  min-height: 100vh;
}

.dark-mode p,
.dark-mode h4,
.dark-mode h3,
.dark-mode span,
.dark-mode h2 {
  color: white;
}

.dark-mode .bg-green-200,
.dark-mode .bg-orange-100,
.dark-mode .bg-pink-100,
.dark-mode .text-gray-800 {
  color: black;
}

.dark-mode .vs__dropdown-toggle {
  border: 1px solid white;
}

* {
  font-size: 16px;
}</style>

